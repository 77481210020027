import { Container, Row, Col } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import s1 from '../assets/img/s1.png'
import s2 from '../assets/img/s2.png'
import s3 from '../assets/img/s3.png'
import s4 from '../assets/img/s4.png'
import s5 from '../assets/img/s5.png'
import s6 from '../assets/img/s6.png'
import s7 from '../assets/img/s7.png'
import s8 from '../assets/img/s8.png'

import '../assets/css/Slides.css'
import { selectSlick } from "../echron/slickSlice";
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import "../assets/css/SliderSlick.css";
function Slides() {
    const slick = useSelector(selectSlick);
    return (
        <section className="Echron-Slides">
            <Container>
                <h2>Why buy with us ?</h2>
                <p>Aspects that make Property4Sure India's leading Real Estate Advisory</p>
                <Slider {...slick.m2}>
                    <div className="p-2">
                        <div className="SliderText">
                            <img src={s1} alt="" />
                            <div className='EchronText'>
                                <h2>Expertise and Experience</h2>
                                <p>
                                With years of industry experience, our team offers unparalleled knowledge of local markets, ensuring you get the best advice and insights.
                                </p>
                                <button className='EchronButton'>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        <div className="SliderText">
                            <img src={s2} alt="" />
                            <div className='EchronText'>
                                <h2>Personalized Service</h2>
                                <p>
                                We tailor our approach to meet your unique needs and preferences, finding properties that perfectly match your lifestyle and budget.
                                </p>
                                <button className='EchronButton'>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        <div className="SliderText">
                            <img src={s3} alt="" />
                            <div className='EchronText'>
                                <h2>Comprehensive Listings</h2>
                                <p>
                                Explore a wide range of properties, from cozy condos to luxurious estates, all conveniently accessible through our user-friendly online platform.
                                </p>
                                <button className='EchronButton'>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        <div className="SliderText">
                            <img src={s4} alt="" />
                            <div className='EchronText'>
                                <h2>Negotiation Expertise</h2>
                                <p>
                                Our skilled negotiators work tirelessly to secure the best price and terms for you, making the buying process smooth and stress-free.
                                </p>
                                <button className='EchronButton'>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        <div className="SliderText">
                            <img src={s5} alt="" />
                            <div className='EchronText'>
                                <h2>Transparency and Trust</h2>
                                <p>
                                We prioritize honesty and integrity, keeping you informed and ensuring your best interests are always our top priority.
                                </p>
                                <button className='EchronButton'>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        <div className="SliderText">
                            <img src={s6} alt="" />
                            <div className='EchronText'>
                                <h2>Comprehensive Support</h2>
                                <p>
                                From securing financing to finalizing paperwork, we offer full support throughout the entire buying process.
                                </p>
                                <button className='EchronButton'>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        <div className="SliderText">
                            <img src={s7} alt="" />
                            <div className='EchronText'>
                                <h2>Community Knowledge</h2>
                                <p>
                                Benefit from our local expertise, providing insights into neighborhoods, schools, amenities, and future developments.
                                </p>
                                <button className='EchronButton'>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        <div className="SliderText">
                            <img src={s8} alt="" />
                            <div className='EchronText'>
                                <h2>Post-Purchase Assistance</h2>
                                <p>
                                Our support continues even after closing, helping you settle into your new home with ease.
                                </p>
                                <button className='EchronButton'>Read More</button>
                            </div>
                        </div>
                    </div>
                </Slider>
                
            </Container>
        </section>
    )

}
export default Slides