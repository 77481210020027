import { setNav, setAnimNav } from "../echron/navSlice";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
// import Loader from "./Loader";
import { useGetNavLinkQuery } from "../echron/clientapi";
import "../assets/css/Navi.css";
function Navi(props) {
  const navRes = useGetNavLinkQuery();
  const dispatch = useDispatch();
  const echronEvNav = () => {
    dispatch(setAnimNav(""));
    setTimeout(
      function () {
        //Start the timer
        dispatch(setNav(false)); //After 1 second, set render to true
      }.bind(this),
      500
    );
  };

//   if (navRes.isLoading) return <Loader mtop="100px" />;
  if (navRes.isError) return <h1>An Error occured {navRes.error.error}</h1>;
	if(navRes.isSuccess) {
		const echronMap = navRes.data
		return (
			<div className="EchronNav">
				{/* <a onclick="openhandel()" onClick={echronEvNav.bind()} className="open-handel"> MENU</a> */}
				<div className={"echron-show " + props.animate}>
					<div className="echron-text">
						<i
							className="fa fa-long-arrow-right"
							onClick={echronEvNav.bind()}
							aria-hidden="true"
						></i>
						<ul><li><NavLink to="/">Home</NavLink></li>
							{
								echronMap.header.map((link)=>
									
									<li><NavLink to={link.url}>{link.name}</NavLink></li>
								)
							}
							{/* <li><NavLink to="../about-us">About us </NavLink></li> */}
						</ul>
					</div>

					<div className="contact-container">
						<h4>Need Any Help ?</h4>
						<div className="contact-text">
							<NavLink to="tel:9910902063">
								{" "}
								<span className="material-symbols-outlined">
									<i className="fa fa-phone"></i>
								</span>{" "}
								+91 9910902063
							</NavLink>
							<NavLink to="tel:8178749372">
								{" "}
								<span className="material-symbols-outlined">
									<i className="fa fa-phone"></i>
								</span>{" "}
								+91 8178749372
							</NavLink>
							<NavLink to="mailto:info@property4sure.com">
								{" "}
								<span className="material-symbols-outlined">
									<i className="fa fa-envelope-o" aria-hidden="true"></i>
								</span>{" "}
								info@property4sure.com
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Navi;
