import React, { useState, useEffect, useCallback, useMemo } from "react";
import './DataFilter.css';
import Right from './Right';
import Left from './Left';
import { useGetOthersQuery } from "../echron/clientapi";

const DataFilter = (props) => {
  const { searchRentSale, bhkValues, category, loksn, sublokValues, devloprFiltersVales, ActiveFilterData, minimumPrice, MaximumPrice, setBdgt, activeArray, setAb, rntsale, setCtg, setSbLoks, setLok, setDevs } = props; 
 
//Sending Value
const rentSales =(r)=>{
  searchRentSale(r)
}

const bhkVals =(b)=>{
  bhkValues(b);
}
const categoryss =(c)=>{
  category(c);
}
const cityfiltrVals=(l)=>{
  loksn(l);
}
const sublokVal =(s)=>{
  sublokValues(s);
}

const devloprFiltersVals =(d)=>{
  devloprFiltersVales(d)
}


  const locsRes = useGetOthersQuery(
    {
      type: 'location', 
      chain: '2', 
      parent_id: 'null'}
  );
  
const pathname = window.location.pathname;
const [sortedData, setSortedData] = useState([]);
const [minPrice, setMinPrice]=useState(); 
const [mznPrice, setMznPrice]=useState(); 
const [sqr, setSqr]=useState();


 useEffect(()=>{
  filterAndSortData();
 }, [props.data, minPrice, mznPrice])

const minimumprice =(mn)=>{
  setMinPrice(mn);
  minimumPrice(mn);
}
const maximumprice = (mx) =>{
  setMznPrice(mx);
  MaximumPrice(mx);
}

const squarefeet =(sq)=>{
  setSqr(sq);
}


const filterAndSortData = () => {
  var filteredData = [...props.data];   
  setSortedData(filteredData);
};

 let priceconvert = (val) => {
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + " Cr";
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + " Lakh";
  }
  // else if(val >= 1000) val = (val/1000).toFixed(0) + ' K';
  return val;
};



  return (
    <section className="searchingSection">
          {/* Left Side Filter Start */}
          <div className="leftFilterBox">
              <Left priceconvert={priceconvert} minRs={minimumprice} maxRs={maximumprice} locsRes={locsRes} sortedDatas={props.data} sqfeet={squarefeet} changeRentSaleVal={rentSales} bhkVal={bhkVals} categorys={categoryss} cityfiltrVal={cityfiltrVals} sublokFilter={sublokVal} devloprFiltersVal={devloprFiltersVals} ActiveFilterData={ActiveFilterData} setBdgt={setBdgt} activeArray={activeArray} setAb={setAb} rntsale={rntsale} setCtg={setCtg} setLok={setLok} setSbLoks={setSbLoks} setDevs={setDevs}/>
          </div>

          {/* Right Side Section Start */}

          <div className="rightDataBox">
              <Right sortedDatas={sortedData} priceconvert={priceconvert} />
          </div>

    </section>
  )
}

export default DataFilter

