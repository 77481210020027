import { Container, Row} from 'react-bootstrap'
import { useGetOthersQuery } from '../echron/clientapi'
import List_card from './List_card'
import Loader from './Loader'
import '../assets/css/Location.css'
function Location(props) {
	/* const order = props.order
	const limit = props.limit
	const rand = props.rand
  const locsRes = useGetLocsQuery({order: order, limit: limit, rand: rand}) */
	const locsRes = useGetOthersQuery({type: 'location', chain: '2', parent_id: 'null'});
	if(locsRes.isLoading) return <Loader mtop="100px" />
	if(locsRes.isError) return <h1>An Error occured {locsRes.error.error}</h1>
	if(locsRes.isSuccess) {
		return (
			<section className="Echron-Category">
				<Container>
					<h2>FEATURED CITIES</h2>
					<p>Explore Prime Properties Based On Your Recommendation</p>
					<Row className="py-3">
						{
							locsRes.data.location.map((post)=>
								<List_card  flex="6" key={post.id} name={post.name} image={post.image} url={post.url} className="child1" />
							)
						}
					</Row>
				</Container>
			</section>
		)
	}
}
export default Location