import React, { useEffect, useState, useMemo } from 'react';
import { useGetSearchPostQuery, useGetSearchSubLokQuery, useGetOthersQuery, useGetDevsQuery } from './echron/clientapi';
import Loader from './component/Loader';
import Filter_List from './component/Filter_List';
import { useLocation, useNavigate } from 'react-router-dom';
import Banner from './component/Banner';
import DataFilter from './Filter/DataFilter';

const Search = () => {

  const navigate = useNavigate();
  const location = useLocation();
  let pathname = window.location.pathname;


const [apartments, setApartments] = useState([]);
const { data, isError, isLoading } = useGetSearchPostQuery();

useEffect(() => {
  if (data) {
    setApartments(data.post);
  }
}, [data]);

  const locsRes = useGetOthersQuery(
    {
      type: 'location', 
      chain: '2', 
      parent_id: 'null'}
  );
  const sublocation = useGetSearchSubLokQuery();
  const devsRes = useGetDevsQuery();
  const developers = devsRes?.data?.developer || [];

  const formatUrl = (url) => {
    return url.replace(/\++/g, '+').replace(/^\+|\+$/g, '').replace(/\&/g, '');
  };


  
  useEffect(() => {
    const cleanedPathname = formatUrl(pathname);
    if (cleanedPathname !== pathname) {
      navigate(cleanedPathname);
    }
    // redirect url if + more then 1
    const redirectPattern = /^\/search\/(\+{1,})(?!.+)/;
    if (redirectPattern.test(cleanedPathname)) {
      navigate('/search/');
    }
  }, [pathname, navigate]);

  // Helper function to normalize text
  const normalizeText = (text) => {
    return text
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .replace(/[^\w\s]/g, ' ')
      .replace(/(\d+)\s*lakh/g, '') 
      .replace(/(\d+)\s*cr/g, '')
      .trim();
  };

  const normalizedSearchTerm = useMemo(() => {
    const match = pathname.match(/\/search\/(.+)/);
    if (match) {
      let searchTerm = decodeURIComponent(match[1]);
      const bhkReplacements = {
        '1rk':'1rk',
        '2 bhk':'2bhk',
        '3 bhk':'3bhk',
        '4 bhk':'4bhk',
        '5 bhk':'5bhk',
        '6 bhk':'6bhk',
        '7 bhk':'7bhk',
        'gurugram': 'gurgaon',
      };
      
      Object.keys(bhkReplacements).forEach(key => {
        searchTerm = searchTerm.replace(new RegExp(key, ''), bhkReplacements[key]);
      });
      return normalizeText(searchTerm);
    }
    return '';
  }, [pathname]);

  const searchWords = useMemo(() => {
    return normalizedSearchTerm.split(' ').filter(word => word.trim().length > 0);
  }, [normalizedSearchTerm]);



  const [rntsale, setRntsale] = useState([]);
  const [bhk, setBhk] = useState([]);
  const [ctr, setCtr] = useState([]);
  const [lok, setLok] = useState([]);
  const [sbLoks, setSbLoks] = useState([]);
  const [devs, setDevs] = useState([]); 
  const [nName, setNName] = useState([]);
  const [minbudget, setMinbudget] = useState([]);
  const [maxbudget, setMaxbudget] = useState([]);
  const [area, setArea] = useState([])


// Rent Sale
 
const searchRentSale = (d) => {
  setRntsale([d]);
};

// BHK Value
const bhkValues =(x)=>{
  let b = x+"bhk";
  setBhk([b]);
  // setBhk((prev) => {
  //   const exists = prev.includes(b);
  //   if (exists) {
  //     return prev.filter(item => item !== b);
  //   } else {
  //     return [...prev, b];
  //   }
  // });
}

// Category
const category =(c)=>{
  setCtr([c])
  // setCtr((prev) => {
  //   const exists = prev.includes(c);
  //   if (exists) {
  //     return prev.filter(item => item !== c);
  //   } else {
  //     return [...prev, c];
  //   }
  // });
}

//Location 
const loksn = (l) => {
  setLok([l])
  // setLok((prev) => {
  //   if (!Array.isArray(prev)) {
  //     return [l];
  //   }
  //   const exists = prev.includes(l);
  //   if (exists) {
  //     return prev.filter(item => item !== l);
  //   } else {
  //     return [...prev, l];
  //   }
  // });
};

// Sub location

const sublokValues =(sb)=>{
  setSbLoks([sb])
  // setSbLoks((prev) => {
  //   const exists = prev.includes(sb);
  //   if (exists) {
  //     return prev.filter(item => item !== sb);
  //   } else {
  //     return [...prev, sb];
  //   }
  // });
}

//Devlopers

const devloprFiltersVales =(d)=>{
  setDevs([d]);
  // setDevs((prev) => {
  //   const exists = prev.includes(d);
  //   if (exists) {
  //     return prev.filter(item => item !== d);
  //   } else {
  //     return [...prev, d];
  //   }
  // });
}

const [bdgt, setBdgt] = useState([]);
const minimumPrice=(d)=>{
  setMinbudget(d)
}
const MaximumPrice=(d)=>{
  setMaxbudget(d)
}
const budgetArray = maxbudget !== undefined ? [minbudget+"-"+maxbudget] : [0+"-"+600000000];
useEffect(()=>{
  setBdgt(budgetArray);
}, budgetArray);

const ActiveFilterData = {
  "mainurl": searchWords.slice(1),
  "RentSale": rntsale,
  "Bhk": bhk,
  "ctr": ctr,
  "lok": lok,
  "sbloks": sbLoks,
  "devs": devs,
  "nName": nName,
  "bdgt":bdgt,
};

const activeArray = useMemo(() => {
  const combinedArray = [
    ...searchWords.slice(1),
    ...rntsale,
    ...bhk,
    ...ctr,
    ...lok,
    ...sbLoks,
    ...devs,
    ...nName
  ];
  return Array.from(new Set(combinedArray));
}, [searchWords, rntsale, bhk, ctr, lok, sbLoks, devs, nName]);


const [ab, setAb] = useState([]);
const [rs, setRs] = useState([]);
const [ctg, setCtg] = useState([]);
const [plok, setPlok] = useState([]);
const [psublok, setPsublok] = useState([]);
const [pdevs, setPdevs] = useState([]);
const [nNames, setNNames] = useState([]);
const [pHeading, setPHeading] = useState([]);
const [bdgts, setBdgts] = useState([]);
const [ptags, setPtags] = useState([]);
useEffect(() => {

  const RentSaleOptions = ['sale', 'rent'];
  const rsMatches = RentSaleOptions.filter(r => activeArray.includes(r));
  setRs(rsMatches);

  const bhkOptions = ['1bhk', '2bhk', '3bhk', '4bhk', '5bhk', '6bhk'];
  const matches = bhkOptions.filter(b => activeArray.includes(b));
  setAb(matches);

  const ctgOptions = ['Office', 'Flats', 'Service Apartments', 'Independent Kothi', 'Penthouse', 'Independent Builder Floor', 'Villas', 'Commercial Land', 'Factory', 'Co-Working', 'Industrial Land', 'Residential Plots', 'Commercial Retail', 'Warehouse Godown', '1RK Studio', 'SCO SCO Plots', 'Cold Storage'];
  const ctgMatches = ctgOptions.filter(b => activeArray.includes(b.toLowerCase()));
  setCtg(ctgMatches);

  const lokOptions = locsRes && locsRes.data && locsRes.data.location || [];

  const lokMatches = lokOptions.filter(b => 
      activeArray.map(item => item.toLowerCase()).includes(b.name.toLowerCase())
  );
  const matchedNames = lokMatches.map(b => b.name);
  setPlok(matchedNames);


  //sub location
  const sublokOptions = sublocation && sublocation.data ? sublocation.data : []; 
  const matchingOptions = sublokOptions.filter((a) => 
    activeArray.join(' ').toLowerCase().includes(a.name.toLowerCase())
  );
  setPsublok(matchingOptions.map(option => option.name));

  //Devlopers
  const developersOptins = developers.filter((a) => 
    activeArray.join(' ').toLowerCase().includes(a.name.toLowerCase())
  );
  setPdevs(developersOptins.map(option => option.name));

  // n name
  const nNameOptions = apartments.filter(i => 
    i.n_name && activeArray.some(h => 
      new RegExp(`\\b${h.toLowerCase()}\\b`, 'i').test(i.n_name.toLowerCase())
    )
  ).map(i => i.n_name.toLowerCase());
  setNNames(nNameOptions);  

  // heading
  const headingOption = apartments
        .filter(i => i.name && searchWords.slice(1).every(h=>i.name.toLowerCase().includes(h)))
        .map(i => i.name.toLowerCase())
        setPHeading(headingOption);

  const tagsOption = apartments
        .filter(i => {
          if (i.taglines) {
            const taglinesArray = i.taglines.split(',').map(tag => tag.trim().toLowerCase());
            return searchWords.slice(1).every(word =>
              taglinesArray.some(tagline => tagline.includes(word.toLowerCase()))
            );
          }
          return false;
        })
        .map(i => i.taglines.toLowerCase());
        setPtags(tagsOption);

}, [activeArray, setRs, setAb, setCtg, setPsublok, setPdevs, setNNames, setPHeading, setPtags]);


const filteredData = apartments.filter((item) => {
  const bdgts = bdgt.join(' ').replace('-', ' ').split(' ');
  
  if (searchWords[0] === "voice") {
    const combinedText = [
      item.name,
      item.n_name,
      item.categroy_name ? item.categroy_name.replace(/\//g, ' '):'',
      item.location,
      item.location && item.location.length > 2 ? item.location[2].replace(/ /g, '') : '',
      item.categroy,
      item.developer?.name,
      item.bedroom ? item.bedroom.split(',').map(b => b.trim() + 'bhk').join(' ') : '',
      item.p_for,
      item.furnished,
      item.p_type,
      item.amount,
      item.max_amount,
      item.taglines
    ].join(' ').toLowerCase();
    const voiceSearchTerms = searchWords.slice(1); 
    return voiceSearchTerms.every(term => combinedText.includes(term.toLowerCase()));

  }else{

    if (searchWords[0] === "tagline") {
      const changeSearchUrl = searchWords.slice(1)
        .map(word => word.replace(/(\d{1,2})(bhk)/i, '$1 $2')) 
        .join(' ').toLowerCase();
      
      if (item.taglines !== null && typeof item.taglines === 'string') {
        return item.taglines
          .toLowerCase()
          .split(',')
          .map(tagline => tagline.trim())
          .includes(changeSearchUrl);
      }
    }
  

    if (searchWords[0] === "search") {
      const combinedText = [
        item.name,
        item.n_name,
        item.categroy_name ? item.categroy_name.replace(/\//g, ' '):'',
        item.location,
        item.location && item.location.length > 2 ? item.location[2].replace(/ /g, '') : '',
        item.categroy,
        item.developer?.name,
        item.bedroom ? item.bedroom.split(',').map(b => b.trim() + 'bhk').join(' ') : '',
        item.p_for,
        item.furnished,
        item.p_type,
        item.amount,
        item.max_amount,
        item.taglines
      ].join(' ').toLowerCase();
 
      const allWordsMatch = activeArray.every(s => combinedText.includes(s.toLowerCase()));
      
      // Check for any matches
      const anyBhkMatch = ab.some(b => combinedText.includes(b.toLowerCase()));
      const anyRntSaleMatch = rs.some(b => combinedText.includes(b.toLowerCase()));
      const anyCtgMatch = ctg.some(c => combinedText.includes(c.toLowerCase()));
      const anyLokMatch = plok.find(l => combinedText.includes(l.toLowerCase()));
      const anysbLokMatch = psublok.some(l => combinedText.includes(l.toLowerCase()));
      const anyDevlopers = pdevs.some(l => combinedText.includes(l.toLowerCase()));
      const anynNames = nNames.some(n => combinedText.includes(n.toLowerCase()));
      const anynTags = ptags.some(t => combinedText.includes(t.toLowerCase()));
     let amtInBudget  = item.amount >= bdgts[0] && item.amount <= bdgts[1];
    console.log(anynTags)
      // Conditions array
      const nNamesConditions = [
        { condition: rs.length > 0, match: anyRntSaleMatch },
        { condition: ab.length > 0, match: anyBhkMatch },
        { condition: plok.length > 0, match: anyLokMatch },
        { condition: ctg.length > 0, match: anyCtgMatch },
        { condition: psublok.length > 0, match: anysbLokMatch },
        { condition: pdevs.length > 0, match: anyDevlopers },
        { condition: nNames.length > 0, match: anynNames },
        { condition: ptags.length > 0, match: anynTags }
      ];
      
      // Determine if there's an exact match
      let isExactMatch = allWordsMatch && amtInBudget;
      for (const { condition, match } of nNamesConditions) {
        if (condition) {
          isExactMatch &&= match;
        }
      }
    
      return isExactMatch;
    }
    
  
  
    
 
  }
 
});

window.scroll({
  top: 500,
  behavior: 'smooth'
});


  if (isLoading) {
    return <div style={{ margin: '2rem 0' }}><Loader /></div>;
  }

  if (isError) {
    return <p style={{ textAlign: 'center', margin: '2rem 0' }}>Error: Data not fetched...</p>;
  }


  return (
    <div>
      <Banner />
      {
        searchWords.length > 1 ?
        <DataFilter data={filteredData} alldatas={data?.post} searchRentSale={searchRentSale} bhkValues={bhkValues} category={category} loksn={loksn} sublokValues={sublokValues} devloprFiltersVales={devloprFiltersVales} ActiveFilterData={ActiveFilterData} minimumPrice={minimumPrice} MaximumPrice={MaximumPrice} setBdgt={setBdgt} activeArray={activeArray} setAb={setBhk} rntsale={setRntsale} setCtg={setCtr} setLok={setLok} setSbLoks={setSbLoks} setDevs={setDevs}/>
       : null
      }
      </div>
  );
};

export default Search;
