import { echronEvInput, useSubmitForm, selectFormResp } from "../echron/formSlice";
import { useDispatch, useSelector } from 'react-redux';

function Form_side() {
  //post api
  const dispatch = useDispatch();
  const submitForm = useSubmitForm();
  const formResps = useSelector(selectFormResp);
  const echronEvSubmit = () => {
    if(!formResps.load) {
      submitForm();
    }
  }
  return (
    <form className="EchronSide">
      <h1 className="echron-common-h1">
        I AM INTERESTED 
      </h1>
      <input type="text" placeholder="Enter Your Name*" className="form-control" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'name'}))}}></input>
      <div className="row g-0">
        {/* <div className="col-4 col-md-5">
          <select id="inputState" className="form-select">
          <option selected="">+91 India...</option>
          <option>America</option>
          <option>China</option>
          </select>
        </div> */}
        <div className="col-12">
        <input type="text" placeholder="Enter Your Phone*" className="form-control" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'phone'}))}}></input>
        </div>
        <input type="text" placeholder="Enter Your Email*" className="form-control" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'email'}))}}></input>
        <textarea name="Message" className="form-control" id="message" rows="3" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'message'}))}} placeholder="Type your message here...."></textarea>
      </div>
      <input type="checkbox"></input> <label>I Would also like to know about home Loans</label>
      <div className="echron-button" onClick={()=>echronEvSubmit()}>
        {formResps.load ? 'Please Wait...' : 'Get a Call'}
      </div>
    </form>
    )
  }
  export default Form_side