import { Container } from 'react-bootstrap'
import '../assets/css/Developers.css'
import Loader from './Loader'
import { selectSlick } from "../echron/slickSlice"
import { useGetDevsQuery } from '../echron/clientapi'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import Slider from "react-slick";
import "../assets/css/SliderSlick.css";
function Developers() {
    const devsRes = useGetDevsQuery()
    const slick = useSelector(selectSlick);

    if(devsRes.isLoading) return <Loader mtop="100px" />
	if(devsRes.isError) return <h1>An Error occured {devsRes.error.error}</h1>
    return (
        <section className="Echron-Sliders">
            <Container>
                <h2>Leading Real Estate Developers</h2>
                <Slider {...slick.m2}>
                    {
                        devsRes.data.developer.map((post)=>
                            <div className="p-2">
                                <div className="SliderText">
                                    <NavLink to={post.url}>
                                        <img src={post.image} alt={post.name} />
                                    </NavLink>
                                </div>
                            </div>  
                        )
                    }
                </Slider>
            </Container>
        </section>
    )

}
export default Developers