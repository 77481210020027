import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { clientApi } from "./clientapi"
import scrollSlice from "./scrollSlice"
import popupSlice from "./popupSlice"
import navSlice from "./navSlice"
import formSlice from "./formSlice"
import slickSlice from "./slickSlice"
export const store = configureStore({
  reducer: {
    [clientApi.reducerPath]: clientApi.reducer,
    scroll: scrollSlice,
    popup: popupSlice,
    nav: navSlice,
    form: formSlice,
    slick: slickSlice
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(clientApi.middleware)
})

setupListeners(store.dispatch)