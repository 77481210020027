import { Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
function List_card(props) {
    return (
        <Col lg={props.flex} key={props.id}>
            <div className="sliderimg">
                <NavLink to={props.url}>
                    <figure>
                        <img src={props.image} alt="" />
                    </figure>
                    <h4>{props.name}</h4>
                </NavLink>
            </div>
        </Col>
    )
}
export default List_card