import { Container, Row, } from "react-bootstrap";
import React from 'react'
import "../assets/css/Popup.css";
import { echronEvInput, useSubmitForm, selectFormResp } from "../echron/formSlice";
import { useDispatch, useSelector } from 'react-redux';

import { setPopup } from "../echron/popupSlice"
function Popup() {


    const echronEvPopRem = () => {
        dispatch(setPopup(false));
    }
     //post api
  const dispatch = useDispatch();
  const submitForm = useSubmitForm();
  const formResps = useSelector(selectFormResp);
  const echronEvSubmit = () => {
    if(!formResps.load) {
      submitForm();
    }
  }
    return (
        <section className="echron-popup">
            <Container>
                <Row>
                    <div className="form-main">
                        <div className="wrapper">
                            <button className="echron-button close"  onClick={echronEvPopRem.bind()}>X</button>
                            <h2>Get a Call Back</h2>
                            <div className="form-group">
                                <input type="text" className="name" placeholder="Name*" required="" minlength="3" maxlength="25" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'name'}))}}></input>
                            </div>
                            <div className="form-group">
                                <input type="email" className="email" name="email" required="" minlength="3" maxlength="25" placeholder="Email*" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'email'}))}}></input>
                            </div>
                            <div className="form-group">
                                <input type="text" className="phone" placeholder="Phone No*" minlength="10" required="" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'phone'}))}}></input>
                            </div>
                            <div className="form-group">
                                <label for="message">Message</label>
                                <textarea name="Message" id="message" rows="2" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'message'}))}} placeholder="Type your message here...."></textarea>
                            </div>
                            <div className="form-group">
                                <input type="hidden" className="page" value="/"></input>
                                <input type="hidden" className="url" value="mapmystudy.com"></input>
                                <input type="hidden" className="token" value="b78a626c0cfa915c23417d439f3b7705"></input>
                                <button type="button" data-path="/" className="cl_pop_submit submit" onClick={()=>echronEvSubmit()}>{formResps.load ? 'Please Wait...' : 'SUBMIT'}</button>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </section>
    );
}
export default Popup;