import React, { useState, useEffect } from "react";

const BudgetFilter = ({priceconvert, minPrice, maxPrice, priceResetValue, setPriceResetValue, minpriceFilter, setMinPriceFilter, maxpriceFilter, setMaxPriceFilter, LABELS, minValue, setMinValue, maxValue, setMaxValue}) => {

    
    const url = window.location.pathname;
    const urlreplace = url.replace("/search/", '').replace(/\+/g, ' ').replace(/\d+sq/g, '').replace("feet", '');

    const convertToNumber = (value) => {
    
        if (typeof value !== 'string') return parseFloat(value);
        value = value.trim();
        if (value.endsWith('lakh')) {
            return parseFloat(value) * 100000; 
        } else if (value.endsWith('cr')) {
            return parseFloat(value) * 10000000;
        }
        return parseFloat(value); 
    };
    
    const pricetermsToMatch = (() => {
      const matches = urlreplace.match(/[\d.]+(?:\s*[a-zA-Z]+)?/g);
      if (matches) {
          return [...new Set(matches.map(convertToNumber).filter(term => term > 10))];
      }
      return []; 
  })();
   
    


    
     
      useEffect(()=>{

        if (pricetermsToMatch.length === 1) {
          pricetermsToMatch.unshift(0);
        } else if (pricetermsToMatch.length > 1) {
          pricetermsToMatch.sort((a, b) => a - b);
        }
        // Create result array
        const resultArray = pricetermsToMatch.length > 1 ? pricetermsToMatch : [0, ...pricetermsToMatch];
        
        const sendingMinMaxPrice =(min, max)=>{
      
            if (min === 0 && max === 600000000) {
              setMinValue(LABELS.indexOf(resultArray[0] === undefined ? 0 : resultArray[0]));
              setMaxValue(LABELS.indexOf(resultArray[1] === undefined ? 600000000 : resultArray[1]));
              minPrice(resultArray[0]); 
              maxPrice(resultArray[1]);
             
          } else {
              minPrice(min); 
              maxPrice(max); 
             
          }
                  
        }
        sendingMinMaxPrice(minpriceFilter, maxpriceFilter);  
           
      }, [pricetermsToMatch, minpriceFilter, maxpriceFilter])
      
 

      useEffect(() => {
        if (priceResetValue) {
            setMinValue(LABELS.indexOf(0));
            setMaxValue(LABELS.indexOf(600000000));
            setMinPriceFilter(0);
            setMaxPriceFilter(600000000);
            setPriceResetValue(false)
        }
    }, [priceResetValue]);

      const minPercent = (minValue / (LABELS.length - 1)) * 100;
      const maxPercent = (maxValue / (LABELS.length - 1)) * 100;

  
      useEffect(() => {
        const handleMinInput = (event) => {
          const value = Number(event.target.value);
          if (value > maxValue) {
            setMinValue(maxValue);
            setMinPriceFilter(LABELS[maxValue]);
          } else {
            setMinValue(value);
            setMinPriceFilter(LABELS[value]);
          }
        };
        // LABELS[minValue]
        const handleMaxInput = (event) => {
          const value = Number(event.target.value);
          if (value < minValue) {
            setMaxValue(minValue);
            setMaxPriceFilter(LABELS[minValue]);
          } else {
            setMaxValue(value);
            setMaxPriceFilter(LABELS[value]);
          }
        };
    
        const minRange = document.getElementById('minRange');
        const maxRange = document.getElementById('maxRange');
    
        minRange.addEventListener('input', handleMinInput);
        maxRange.addEventListener('input', handleMaxInput);
    
        return () => {
          minRange.removeEventListener('input', handleMinInput);
          maxRange.removeEventListener('input', handleMaxInput);
        };
      }, [minValue, maxValue]);
 
    
    const [price, setPrice] = useState({
      minprice: 0,
      maxprice: 600000000
    });
   
    const handleChange = (e)=>{
      setPrice({...price, [e.target.name]: e.target.value});
    }
    
    useEffect(() => {
    
      setMinPriceFilter(price.minprice === '' ? 0 : price.minprice);
      setMaxPriceFilter(price.maxprice === ''? 600000000 : price.maxprice);
      
    }, [price]);



  
  

  return (
    <>
         <div className="filter">
            <div className="filterhead">
              <h6>Budget</h6>
              <i class="fa-solid fa-angle-down"></i>
            </div>

            {/* Range Slide Start             */}

            <div className="slider-container">
      <div className="slider-wrapper">
        <input
          type="range"
          id="minRange"
          className="slider"
          min="0"
          max={LABELS.length - 1}
          value={minValue}
          step="1"
        />
        <input
          type="range"
          id="maxRange"
          className="slider"
          min="0"
          max={LABELS.length - 1}
          value={maxValue}
          step="1"
        />
        <div className="slider-track"></div>
        <div className="slider-values">
          <span
            id="minValue"
            style={{ left: `calc(${minPercent}% - ${document.getElementById('minValue')?.offsetWidth / 2}px)` }}
          >
            {priceconvert(LABELS[minValue]) === 0 ? 0: priceconvert(LABELS[minValue])}
          </span>
          <span
            id="maxValue"
            style={{ left: `calc(${maxPercent}% - ${document.getElementById('maxValue')?.offsetWidth / 2}px)` }}
          >
            {priceconvert(LABELS[maxValue])}
          </span>
        </div>
      </div>
    </div><br/>

    
            {/* Range Slide End             */}
          </div>

          <div className="minmaxbudgetBox">
        <div className="minmxbgt">
            {/* <span>Min Budget</span> */}
            <input type="text" placeholder="Min Budget" name="minprice" onChange={handleChange}/>
        </div>
        <div className="minmxbgt">
            {/* <span>Max Budget</span> */}
            <input type="text" placeholder="Max Budget" name="maxprice" onChange={handleChange}/>
        </div>
    </div>
    </>
  )
}

export default BudgetFilter
