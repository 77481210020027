import React, { useState } from 'react';
import PropertyRentSale from './PropertyRentSale';
import BudgetFilter from './BudgetFilter';
import BhkFilter from './BhkFilter';
import AppliedFilter from './AppliedFilter';
import PropertyCategory from './PropertyCategory';
import CityFilter from './CityFilter';
import SubLocation from './SubLocation';
import DevloperFilter from './DevloperFilter';

const Left = ({ priceconvert, minRs, maxRs, locsRes, sortedDatas, sqfeet, changeRentSaleVal, bhkVal, categorys, cityfiltrVal, sublokFilter, devloprFiltersVal, ActiveFilterData, setBdgt, activeArray, setAb, rntsale, setCtg, setLok, setSbLoks, setDevs }) => {
  const [minp, setMinp]= useState();
  const [maxp, setMaxp]= useState();

  const rentsale =(r)=>{
    changeRentSaleVal(r)
  }
  const bhkfiltrs =(b)=>{
    bhkVal(b)
  }
  const categoryfltrs =(c)=>{
    categorys(c);
  }

  const cityfiltrs =(l)=>{
    cityfiltrVal(l);
  }

 const subloksns=(s)=>{
    sublokFilter(s);
 }

 const devloprFilters = (d)=>{
  devloprFiltersVal(d);
 }


  const handleMinPriceChange = (mn) => {
    minRs(mn);
    setMinp(mn);
  };

  const handleMaxPriceChange = (mx) => {
    maxRs(mx);
    setMaxp(mx);
  };

  const [priceResetValue, setPriceResetValue] = useState(false);

  const togglePriceReset = () => {
      setPriceResetValue(prev => {
          const newValue = !prev;
          return newValue; // Update the state
      });
  };
const squareFeetActv =(x)=>{
  sqfeet(x);
}
const [a, setA] = useState([]);
const sendLokId =(i)=>{
  setA(i);
}

const [minpriceFilter, setMinPriceFilter] = useState();
const [maxpriceFilter, setMaxPriceFilter] = useState();
const LABELS = [
  0, 50, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000,
  12000, 13000, 14000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000,
  55000, 60000, 65000, 70000, 75000, 80000, 85000, 90000, 100000, 125000,
  150000, 175000, 200000, 225000, 250000, 275000, 300000, 400000, 500000,
  600000, 700000, 800000, 900000, 1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000, 20000000, 30000000, 40000000,
  50000000, 100000000, 200000000, 300000000, 400000000, 500000000, 600000000,
];  


const [minValue, setMinValue] = useState(0);
const [maxValue, setMaxValue] = useState(LABELS.length - 1); 

const resetValues = () => {
  setMinValue(0);
  setMaxValue(600000000);
  setMinPriceFilter(0);
  setMaxPriceFilter(600000000);
};

  return (
    <div>
      <AppliedFilter locsRes={locsRes} minp={minp} maxp={maxp} priceconvert={priceconvert} togglePriceReset={togglePriceReset} sortedDatas={sortedDatas} squareFeet={squareFeetActv} ActiveFilterData={ActiveFilterData} rentsaleVlaue={rentsale} bhkfiltrD={bhkfiltrs} categoryfltr={categoryfltrs} cityfiltr={cityfiltrs} subloksns={subloksns} devloprFilter={devloprFilters}
      setBdgt={setBdgt}
      resetValues={resetValues}
      activeArray = {activeArray}
      setAb ={setAb}
      rntsale = {rntsale}
      setCtg ={setCtg}
      setLok={setLok}
      setSbLoks ={setSbLoks}
      setDevs={setDevs}
      />

      <div className="scrollable-filter">
      <PropertyRentSale rentsaleVlaue={rentsale} activeArray = {activeArray}/>
      <BudgetFilter 
        priceconvert={priceconvert}
        priceResetValue={priceResetValue}
        setPriceResetValue={setPriceResetValue}
        minPrice={handleMinPriceChange} 
        maxPrice={handleMaxPriceChange} 
        ActiveFilterData={ActiveFilterData}
        minpriceFilter={minpriceFilter}
        setMinPriceFilter={setMinPriceFilter}
        maxpriceFilter={maxpriceFilter}
        setMaxPriceFilter={setMaxPriceFilter}
        setMinValue={setMinValue}
        minValue={minValue}
        setMaxValue={setMaxValue}
        maxValue={maxValue}
        LABELS={LABELS}
      />
      <BhkFilter bhkfiltr={bhkfiltrs} activeArray = {activeArray}/>
      <PropertyCategory categoryfltr={categoryfltrs} activeArray = {activeArray}/>
      <CityFilter 
        locsRes={locsRes} cityfiltr={cityfiltrs}
        lokId={sendLokId}
        activeArray = {activeArray}
        setLok={setLok}
      />
      <SubLocation loksid={a} subloksns={subloksns} activeArray = {activeArray} />
      <DevloperFilter devloprFilter={devloprFilters} ActiveFilterData={ActiveFilterData} />
      </div>
    </div>
  );
};

export default Left;
