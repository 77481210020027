import { Row, Col, Container } from "react-bootstrap"
// import Years_card from '../component/Years_card'
import '../assets/css/Years.css'
// import y1 from '../assets/img/y1.png'
// import y2 from '../assets/img/y2.png'
// import y3 from '../assets/img/y3.png'
function Years() {
    return (
        // <section className="Echron-Years">
        //     <Container>
        //         <Row>
        //             <Years_card
        //                 img={y1}
        //                 text="25+ YEARS EXPERIENCE"
        //             />
        //             <Years_card
        //                 img={y2}
        //                 text="share your position date"
        //             />
        //             <Years_card
        //                 img={y3}
        //                 text="Easy and cost effective ordering we undertake third party duties also"
        //             />
        //         </Row>
        //     </Container>
        // </section>
        <section className="echron-counters overview-bgi">
        <Container>
            <Row>
                <div className="col-md-12 col-lg-12 col-lg-12">
                    <div className="main-title text-center">
                        <h1>What Makes Us The Preferred Choice</h1>
                    </div>
                </div>
                <Col lg={3}>
                    <div className="echron-counter-box">
                        <a href="#" data-html="true" title="" data-toggle="popover" data-trigger="hover" data-content="Updating with new choices every hour, we help you get right property at right prices." data-original-title="Maximum Choices">
                            <i className="fa fa-list"></i>
                            <h6>Maximum Choices</h6>
                        </a>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="echron-counter-box">
                        <a href="#" data-html="true" title="" data-toggle="popover" data-trigger="hover" data-content="Taking buying &amp; renting needs of clients at top priority, we promise reliability." data-original-title="Buyers Trust Us">
                            <i className="fa fa-check-square"></i>
                            <h6>Buyers Trust Us</h6>
                        </a>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="echron-counter-box">
                        <a href="#" data-html="true" title="" data-toggle="popover" data-trigger="hover" data-content="Taking buying &amp; renting needs of clients at top priority, we promise reliability." data-original-title="Seller Prefer Us">
                            <i className="fa fa-thumbs-up"></i>
                            <h6>Seller Prefer Us</h6>
                        </a>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="echron-counter-box">
                        <a href="#" data-html="true" title="" data-toggle="popover" data-trigger="hover" data-content="Taking buying &amp; renting needs of clients at top priority, we promise reliability." data-original-title="Expert Guidance">
                            <i className="fa fa-user-md"></i>
                            <h6>Expert Guidance</h6>
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
     </section>
    )
}
export default Years

