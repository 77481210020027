import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CityFilter = ({ locsRes, cityfiltr, lokId, activeArray, setLok }) => {
  const navigate = useNavigate();
  let cityUrl = window.location.pathname;
  const cityUrlReplace = cityUrl.replace("/search/", "").replace(/\+/g, " ");
  const [loks, setLoks] = useState([]);
  const handleLocation = (option, id) => {
    locsRes.data.location.forEach((itm) => {
      const lowerCaseName = itm.name.toLowerCase();
    
      if (activeArray.includes(lowerCaseName)) {
        if (cityUrlReplace.includes(lowerCaseName)) {
          const updatedUrl = cityUrlReplace.replace(lowerCaseName, '').replace(/^\+|\+$/g, '')
          .replace(/\+\+/g, '+').replace(/ /g, '')+"+"+option;
          setLok([]);
          navigate(`../search/${updatedUrl}`); 
        }
      }
    });
    cityfiltr(option);
    setLoks((prev) => {
      const exists = prev.includes(id);
      return exists ? prev.filter(item => item !== id) : [...prev, id];
    });
  };

  lokId(loks);

  useEffect(() => {
    if (locsRes.data === undefined) {
      console.log("Data not found");
    } else {
      locsRes.data.location.forEach((itm) => {
        if (activeArray.includes(itm.name.toLowerCase())) {
          lokId([itm.id]);
        }
      });
    }
  }, [lokId, locsRes, activeArray]);
  
  

  return (
    <>
      <div className="filter">
        <div className="filterhead">
          <h6>Location</h6>
          <i className="fa-solid fa-angle-down"></i>
        </div>
        <div className="lokflilterbox">
          {locsRes.data === undefined
            ? "Data not found"
            : locsRes.data.location.map((itm) => (
                <div className="localityfilter" key={itm.id}>
                  <input
                    type="radio"
                    name="btn"
                    onChange={(e) => {
                      handleLocation(itm.name.toLowerCase(), itm.id);
                    }}
                    checked={activeArray.includes(itm.name.toLowerCase())}
                  />
                  {itm.name}
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default CityFilter;
