import '../assets/css/Add.css'
function Add() {
    return (
        // <section className="Echron-Add">
        //     <div className="promation-banner">
        //         <img src={addbanner} alt="" />
        //         <h3>Promational<br/> Property<br/><span>Owned By Many,<br/>Cherished By Everyone!</span></h3>
        //     </div>
        // </section>
        <section>
        <div className="echron-intro">
            <div className="row">
                <div className="col-lg-9 col-md-7 col-sm-12">
                    <div className="echron-intro-text text-center">
                        <h3 className="hidden-mobile">Real-Estate Properties At Best Location, At Best Price </h3>
                        <h3 className="hidden-desktop d-none">Real-Estate Properties<br/> At Best Location, At Best Price </h3>
                    </div>
                </div>
                <div className="col-lg-3 col-md-5 col-sm-12 text-center best-price-div">
                    <a href="#" className="btn echron-btn-md best-price">CONTACT US</a>
                </div>
            </div>
        </div>
    </section>
    )

}
export default Add