import { Container} from 'react-bootstrap'
import '../assets/css/Category.css'
import { NavLink } from 'react-router-dom'
import Loader from './Loader'
import { useGetCatsQuery } from '../echron/clientapi'

function Category() {
    const catsRes = useGetCatsQuery(5)
	if(catsRes.isLoading) return <Loader mtop="100px" />
	if(catsRes.isError) return <h1>An Error occured {catsRes.error.error}</h1>
	if(catsRes.isSuccess) {
    return (
        
        <section className="EchronTrending">
            <Container>
                <div className='ul'>
                    {
                        catsRes.data.category.map((post)=>
                            <div className="li" key={post.id}>
                                <NavLink to={post.url}>
                                    <figure>
                                        <img src={post.image} alt="" />
                                    </figure>
                                    <div className="Matter">
                                        <h4 className="h4">{post.name}</h4>
                                        {/* <p className="Containt">CATEGORY</p> */}
                                    </div>
                                </NavLink>
                            </div>
                        )
					}
                </div>
            </Container>
        </section>
    )
    }

}
export default Category