import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const PropertyCategory = ({categoryfltr, activeArray}) => {
  const navigate = useNavigate();
  const [isShowingAll, setIsShowingAll] = useState(false);
  const itemsToShowInitially = 4;
  const items = [
    "Office",
    "Flats",
    "Service Apartments",
    "Independent Kothi",
    "Penthouse",
    "Independent Builder Floor",
    "Villas",
    "Commercial Land",
    "Factory",
    "Co-Working",
    "Industrial Land",
    "Residential Plots",
    "Commercial Retail",
    "Warehouse Godown",
    "1RK Studio",
    "SCO SCO Plots",
    "Cold Storage",
  ];

  const handleShowMoreClick = () => {
    setIsShowingAll(!isShowingAll);
  };

  
const addctr =(c)=>{
 
    // let pathname = window.location.pathname;
    // let result = pathname.replace("/search/", '');
    // if(result.includes(c)){
    //     const updatedResult = result.replace(c, '')
    //     .replace(/^\+|\+$/g, '')
    //     .replace(/\+\+/g, '+');
    //     navigate(`../search/${updatedResult}`);

    // }else{
    //     let r = result+"+"+c;
    //     navigate("../search/"+r);
    // }
    
}


let ctrurl = window.location.pathname;
const ctrurlreplace = ctrurl.replace("/search/", '').replace(/\+/g, ' ');

  const handleCtrClick =(x)=>{
    categoryfltr(x.toLowerCase())
    addctr(x.toLowerCase().replace(/ /g, '+'));
  } 


  return (
    <>
      <div className="filter">
        <div className="filterhead">
          <h6>Type of property</h6>
          <i class="fa-solid fa-angle-down"></i>
        </div>
        <div id="proprtyTypeFilter">
          <ul className="proprtyTypeFilter">
            {items
              .slice(0, isShowingAll ? items.length : itemsToShowInitially)
              .map((item, index) => (
                <li key={index} className={activeArray.includes(item.toLowerCase()) ? "activeFilter":''} onClick={()=>handleCtrClick(item)}>
                  <i class="fa-solid fa-plus"></i> {item}
                </li>
              ))}
          </ul>
          {items.length > itemsToShowInitially && (
            <button onClick={handleShowMoreClick}>
              {isShowingAll ? "Show Less" : "+Show More"}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default PropertyCategory;
