import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetSearchSubLokQuery } from "../echron/clientapi";
import { useGetDevsQuery } from "../echron/clientapi";
import Loader from "../component/Loader";
const AppliedFilter = ({
  locsRes,
  minp,
  maxp,
  priceconvert,
  togglePriceReset,
  squareFeet,
  ActiveFilterData,
  setRntsale,
  rentsaleVlaue,
  bhkfiltrD,
  categoryfltr,
  cityfiltr,
  subloksns,
  devloprFilter,
  setBdgt,
  resetValues,
  activeArray,
  setAb,
  rntsale,
  setCtg,
  setLok,
  setSbLoks,
  setDevs  
}) => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const sublocation = useGetSearchSubLokQuery();
  const devsRes = useGetDevsQuery();
  const developers = devsRes?.data?.developer || [];

  const items = [
    "Office",
    "Flats",
    "Apartments",
    "Service Apartments",
    "Independent Kothi",
    "Penthouse",
    "Independent Builder Floor",
    "Villas",
    "Commercial Land",
    "Factory Space",
    "Co-Working",
    "Industrial Land",
    "Residential Plots",
    "Commercial Retail",
    "Warehouse Godown",
    "1RK Studio",
    "SCO SCO Plots",
    "Cold Storage",
  ];

  // Remove Rent Sale Bhk
  const removeAct = (x) => {
    if(x === "rent"){
      rntsale([]);
    }
    if(x === "sale"){
      rntsale([]);
    }
    const bhkOptions = ['1bhk', '2bhk', '3bhk', '4bhk', '5bhk', '6bhk'];
    bhkOptions.filter((b) =>{
      if(b === x){
        setAb([]);
      }
    })
   
    const ctgOptions = ['Office', 'Flats', 'Service Apartments', 'Independent Kothi', 'Penthouse', 'Independent Builder Floor', 'Villas', 'Commercial Land', 'Factory', 'Co-Working', 'Industrial Land', 'Residential Plots', 'Commercial Retail', 'Warehouse Godown', '1RK Studio', 'SCO SCO Plots', 'Cold Storage'];
    ctgOptions.filter((c) =>{
      if(c.toLowerCase() === x){
        setCtg([]);
      }
    });

    locsRes.data.location.filter((itm) => {
      if (itm.name.toLowerCase() === x) {
        setLok([]);
      }
    });

    sublocation.data.filter((sb)=>{
      if(sb.name.toLowerCase() === x){
        setSbLoks([]);
      }
    });

    developers.filter((d)=>{
      if(d.name.toLowerCase() === x){
        setDevs([]);
      }
    })

    let result = url.replace("/search/", "");
    if(result.replace(/\+/g, ' ').includes(x)){
      let a = x.replace(/ /g, '+');
      navigate(`../search/${result.replace(a, '')}`);
    }    
}

 

let priceconverts = (vals) => {
  return vals.replace('-', ' ').split(' ').map((val) => {
    val = Number(val);
    if (val >= 10000000) {
      return (val / 10000000).toFixed(2) + "Cr ";
    } else if (val >= 100000) {
      return (val / 100000).toFixed(2) + "Lakh ";
    } else if (val >= 1000) {
      return (val / 1000).toFixed(0) + 'K ';
    }
    
    return val;
  });
};


const removeValStorage=()=>{
  localStorage.removeItem("searchTerm");
}

const resultArray = url
  .replace('/search/', '')
  .replace('=', ' ')
  .replace('+', ' ')
  .split(' ')
  .map(item => item.trim());


  return (
    <div className="appliedFilter">
      <div className="applyBox">
        <h5>Applied Filter</h5>
        <Link
          to="./search/"
          onClick={(e) => {
            e.preventDefault();
            navigate("../search/");
            removeValStorage();
          }}
        >
          Clear All
        </Link>
      </div>
      {
        resultArray[0] !== "voice" ?
        <div className="filter-applied-box">
      <ul>
        {activeArray.map((x) => (
          <li key={x} className="activeFilter actifltr" onClick={() => removeAct(x)}>
            <span>{x}</span>
            <i className="fa-solid fa-xmark fltrXmark"></i>
          </li>
        ))}
      </ul>

      {/* <ul>
      {!activeArray.includes("0-600000000") && !activeArray.every(e=>[0, 600000000].includes(e)) && activeArray.map((b) => (
      <li key={b} className="activeFilter" onClick={() => removeAct(b)}>
        <span>{priceconverts(b).join(' ').replace(' ', ' to ').trim()}</span>
        <i className="fa-solid fa-xmark fltrXmark"></i>
      </li>
  ))
}



      </ul> */}

      </div>
      : ''
      }
      
    </div>
  );
};

export default AppliedFilter;
