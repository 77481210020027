import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux'; // Importing useSelector
import { usePostEnqsMutation } from './clientapi';

function generateToken(n=100) {
  var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var token = '';
  for(var i = 0; i < n; i++) {
    token += chars[Math.floor(Math.random() * chars.length)];
  }
  return token;
}

// Define your custom React Hook for submitting the form
export const useSubmitForm = () => {
  const [enqPost] = usePostEnqsMutation();
  const formData = useSelector(selectForm);
  const dispatch = useDispatch();

  const submitForm = async () => {
    
    try {
      // Call the post API
      dispatch(setFormResp({load: true}))
      const response = await enqPost(formData);
      if(response.error) {
        const errorList = []
        const name = response.error.data.result.name
        const email = response.error.data.result.email
        const phone = response.error.data.result.phone
        const token = response.error.data.result.token
    
        name && errorList.push(name)
        email && errorList.push(email)
        phone && errorList.push(phone)
        token && errorList.push(token)
        
        dispatch(setFormResp({error: true, errorList: errorList}))
        //console.log('error', response.error)
      }
      else {
        alert(response.data.result);
        window.location.reload();
      }
    }
    catch (error) {
      // Handle error
      console.error('Error submitting form:', error);
    }
    finally {
      dispatch(setFormResp({load: false}))
    }
  };
  return submitForm;
};


const formSlice = createSlice({
  name: 'form',
  initialState: {
    formFields: {name: '', email: '', phone: '', message: '', page: 'index page', token: generateToken()},
    formResps: {load: false, error: false, errorList: []},
  },
  reducers: {
    echronEvInput: (state, action) => {
      return {
        ...state,
        formFields: {
          ...state.formFields,
          [action.payload.type]: action.payload.value,
        },
      };
    },
    setForm: (state, action) => {
      state.formFields = { ...state.formFields, ...action.payload };
    },
    setFormResp: (state, action) => {
      state.formResps = { ...state.formResps, ...action.payload };
    },
  },
});

export const { setForm, echronEvInput, echronEvSubmitTest, setFormResp } = formSlice.actions;
export const selectForm = (state) => state.form.formFields;
export const selectFormResp = (state) => state.form.formResps;

export default formSlice.reducer;