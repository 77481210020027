import { Container, Row } from "react-bootstrap";
import { NavLink } from 'react-router-dom'
import Loader from './Loader'
import { useGetFootLinkQuery } from '../echron/clientapi'
import "../assets/css/FootLinks.css";
function FootLinks() {
  const footRes = useGetFootLinkQuery()

  if(footRes.isLoading) return <Loader mtop="100px" />
	if(footRes.isError) return <h1>An Error occured {footRes.error.error}</h1>
  if(footRes.isSuccess) {
    const echronMap = footRes.data
    return (
      <section className="echron-properties">
        <Container>
          <Row>
            <div className="col">
              <div className="first">
                <ul>
                  <li>Property in Top Country</li>
                  {
                    echronMap.country.map((link)=>
                      <li><NavLink to={link.url}>{link.name}</NavLink></li>
                    )
                  }
                </ul>
              </div>
              <div className="second">
                <ul>
                  <li>Property in Top State</li>
                  {
                    echronMap.state.map((link)=>
                      <li><NavLink to={link.url}>{link.name}</NavLink></li>
                    )
                  }
                </ul>
              </div>
              <div className="third">
                <ul>
                  <li>Property in Top City</li>
                  {
                    echronMap.city.map((link)=>
                      <li><NavLink to={link.url}>{link.name}</NavLink></li>
                    )
                  }
                </ul>
              </div>
              <div className="fourth">
                <ul>
                  <li>Property in Top Popular Localitis</li>
                  {
                    echronMap.localitis.map((link)=>
                      <li><NavLink to={link.url}>{link.name}</NavLink></li>
                    )
                  }
                </ul>
              </div>
              <div className="fifth">
                <ul>
                  <li>Popular Developer</li>
                  {
                    echronMap.developer.map((link)=>
                      <li><NavLink to={link.url}>{link.name}</NavLink></li>
                    )
                  }
                </ul>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    );
  }
}
export default FootLinks;
