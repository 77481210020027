import React from 'react'
import a1 from '../assets/img/about-banner.png'
import a2 from '../assets/img/about.png'
import "../assets/css/About.css";
import FootLinks from './FootLinks';
import Banner from './Banner';

function Aboutinner(props) {
  
  return (
    <>
         
      <section className='about-section'>

         <Banner img2={props.img2} />

        <div className='container about-container'>
          <h2>{props.name}</h2>
            <div className='about-upper'>
                <div className='upper-left'>
                    <img src={a1} alt='about-image'/>
                </div>
                <div className='upper-right'>
                <p>“No Shortcuts to Quality and Commitment” – Not only to mention but to live by this phrase, this is what Property4Sure believes in. Launched about a decade ago, Property4Sure is a real estate advisor that is contributing to a creative transformation of providing the Residential, Commercial, and Industrial properties online. Helping you navigate through the digital world of real estate, Property4Sure offers an exhaustive range of <strong>Real Estate Properties across India</strong>. Property4Sure works on the rudiments of faith, transparency, and expertise &amp; entertains investors, builders, renters, and buyers to browse through the upcoming projects and enjoy the premier marketing platform for their real estate needs. As the nation’s leading Real Estate Provider, Property4Sure not only offers <strong>Residential Apartments, Builder Floors, Luxury Homes, Villas, Penthouses, Commercial Spaces, Co-Working Spaces, Shops</strong> but also <strong>Industrial Lands</strong> and Business <strong>Lands for Sale and Rent</strong>. </p>
                </div>
            </div>
            <div className='about-lower'>
                <p>As you browse through the verified Real Estate Properties in India with Property4Sure, you can be assured of finding the right one that suits your needs. The listing of our properties across India is the track record in itself and thereby we welcome the buyers and renters from around the world to get started with their urban choices. After changing the map of real estate in Delhi NCR, we now stepped into metropolitan cities like Bangalore, Mumbai, Pune, Hyderabad, Chennai, Kolkata, Jaipur, and many more to follow. Broadening the real estate market, we have more than 1,00,000+ Real Estate Properties across India and with every passing day, more than 100 properties are registered by the reputed sellers.</p> 
                <br/>
                <p>Understanding the rapid development across India & the demand for Residential, Commercial, and Industrial Spaces that are rising every day, Property4Sure brings you the best deals of the Real Estate Properties in India. Shaping up the real estate segment, numerous upcoming Real estate projects in India are contributing to the Indian economy effectively which in turn making the Real estate properties one in million. Be it Residential, Commercial, or Industrial, Property4Sure is working hard and leave no stone unturned to bring the best to your choice. Amongst all, Property4Sure is an online platform for the real estate agents, sellers, buyers and brokers to find the best suited Residential, Commercial, and Industrial properties from an updated list quickly, effectively, and inexpensively. Property4Sure strives to blend world-class services and workmanship so that all your needs are fulfilled despite the huge challenges of the same. Moreover, to help you in each step of closing the best real estate deal, our dedicated team works in your benefit. Nonetheless, the transparency we maintain makes our dealings strong and reliable.</p>
                <br/>
                <p>In a nutshell, with the evolving standards of finding Real Estate Properties in India online, Property4Sure shares the most reliable information pertinent to the real estate market. We provide the properties that are away from any market risks. Our experienced team takes pride in having the complete domain knowledge and thus they assist the buyers and renters to make an informed decision. With all the concerned information on investments, market risks, the scope of appreciation, and other requirements, we make the property search quicker, easier, and smarter!</p>
            </div>
        </div>
      </section>
      <FootLinks/>
    </>
  )
}

export default Aboutinner
