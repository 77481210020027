import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

const PropertyRentSale = ({ rentsaleVlaue, activeArray }) => {
    const navigate = useNavigate();
    const [selectedOptions, setSelectedOptions] = useState({
        rent: false,
        sale: false,
    });
    const handleCheckboxChange = (option, isChecked) => {
        if (isChecked) {
            rentsaleVlaue(option);
        } else {
            rentsaleVlaue(option, false);
        }
    };

    const handleCheckboxChange2 = (option) => {
      
        setSelectedOptions((prev) => {
            const newValue = !prev[option];
            updateSearchParams(option);
            return { ...prev, [option]: newValue };
        });
    };

    const updateSearchParams = (option) => {
        let pathname = window.location.pathname;
        let result = pathname.replace("/search/", '');
        let filters = result.split('+').map(filter => filter.trim()).filter(Boolean);

        if (selectedOptions[option]) {
            // remove it
            filters = filters.filter(filter => filter !== option);
        }

        const updatedResult = filters.join('+');
        navigate(`../search/${updatedResult}`);
    };

    return (
        <div className="filter">
            <div className="filterhead">
                <h6>For</h6>
                <i className="fa-solid fa-angle-down"></i>
            </div>
            <div className='salerent-switch'>
                <div className='propertyrentsale'>
                    <input 
                        type="radio"
                        name='rs' 
                        checked={activeArray.includes('rent')}
                        onChange={(e) => {
                            const isChecked = e.target.checked;
                            handleCheckboxChange("rent", isChecked);
                            handleCheckboxChange2('rent');
                        }}
                    />
                    <p>Rent</p>
                </div>
                <div className='propertyrentsale'>
                    <input 
                        type="radio"
                        name='rs' 
                        checked={activeArray.includes('sale')}
                        onChange={(e) => {
                            const isChecked = e.target.checked;
                            handleCheckboxChange("sale", isChecked);
                            handleCheckboxChange2('sale');
                        }}
                    />
                    <p>Sale</p>
                </div>
            </div>
        </div>
    );
};

export default PropertyRentSale;
