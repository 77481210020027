import { createSlice } from '@reduxjs/toolkit';

const navSlice = createSlice({
  name: 'nav',
  initialState: {
    isNaved: false,
    isLoad: false,
    animNav: '',
  },
  reducers: {
    setNav: (state, action) => {
      state.isNaved = action.payload;
    },
    setAnimNav: (state, action) => {
      state.animNav = action.payload;
    },
    setIsLoad: (state, action) => {
      state.isLoad = action.payload;
    },
  },
});

export const { setNav, setAnimNav, setIsLoad } = navSlice.actions;
export const selectNav = (state) => state.nav.isNaved;
export const selectAnimNav = (state) => state.nav.animNav;
export const selectLoad = (state) => state.nav.isLoad;

export default navSlice.reducer;