import { createSlice } from '@reduxjs/toolkit';

const popupSlice = createSlice({
  name: 'popup',
  initialState: {
    isPopuped: false,
  },
  reducers: {
    setPopup: (state, action) => {
      state.isPopuped = action.payload;
    },
  },
});

export const { setPopup } = popupSlice.actions;
export const selectPopup = (state) => state.popup.isPopuped;

export default popupSlice.reducer;