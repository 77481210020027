import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Right = ({ sortedDatas, priceconvert }) => {
  const [sortedData, setSortedData] = useState([]);
  useEffect(() => {
    setSortedData(sortedDatas);
  }, [sortedDatas]);

  const handleSort = (event) => {
    const { value } = event.target;
    let sortedArray = [...sortedData];

    if (value === "") {
      setSortedData(sortedDatas);
    } else if (value === "low") {
      sortedArray.sort((a, b) => a.amount - b.amount);
      setSortedData(sortedArray);
    } else if (value === "high") {
      sortedArray.sort((a, b) => b.amount - a.amount);
      setSortedData(sortedArray);
    }
  };

  return (
    <>
      <div className="listingHead">
        {/* <Link to="../search/">All Property</Link> */}
        <div className="secoundFilter">
          <h5>{sortedData.length} Results | {localStorage.getItem('searchTerm')}</h5>
          <div className="shortby">
            <i className="fa-solid fa-filter"></i>
            <select id="price-filter" onChange={handleSort}>
              <option value="">Relevance</option>
              <option value="low">Price Low to High</option>
              <option value="high">Price High to Low</option>
            </select>
          </div>
        </div>
      </div>
        
      <div className='list-container'>
      {/* Listing Start */}
      {sortedData.length === 0 ? (
        <div className="notfounddata">
          <h4 style={{ margin: "2rem 0", textAlign: "center" }}>
            Data Not Found
          </h4>
          <Link to="../search/">Show All Property</Link>
        </div>
      ) : (
        sortedData.map((itm) => (
          <div className="listingContainer" key={itm.id}>
            <div className="listingImagesBox">
              <p className={`${
                itm.p_for === "rent"
                  ? "listRent"
                  : itm.p_for === "sale"
                  ? "listSale"
                  : ""
              }`}>
                {itm.p_for}
              </p>
              <div className="listingimg">
                {itm.gallery && itm.gallery.length > 0 && (
                  <img src={itm.gallery[0].image} alt="Property" />
                )}
              </div>
            </div>

            <div className="listingContent">
              <div className="ListContenthead">
                <h5>{itm.name}</h5>
                <div className="listingCtr">
                  <p>{itm.categroy_name}</p>
                </div>
              </div>
              <div className="listheadLok">
                <i className="fa-solid fa-location-dot"></i>
                <span>{`${itm.location[1]}, ${itm.location[2]}`}</span>
              </div>

              <div className="listingFeature">
                <div>
                  <i className="fa-solid fa-bed"></i>
                  <p>{itm.bedroom} BHK</p>
                </div>
                <div>
                  <i className="fa-solid fa-chart-area"></i>
                  <p>{itm.area} SQ. FT.</p>
                </div>
                <div>
                  <i className="fa-solid fa-certificate"></i>
                  <p>Verified</p>
                </div>
                <div>
                  <i className="fa-solid fa-chart-area"></i>
                  <p>{itm.p_for}</p>
                </div>
                <div>
                  <i className="fa-solid fa-chart-area"></i>
                  <p>Residential</p>
                </div>
              </div>

              <p id="listingSmD">
                {itm.n_description === null
                  ? ""
                  : itm.n_description.length > 150
                  ? `${itm.n_description.slice(0, 150)}...`
                  : itm.n_description}
              </p>

              <div className="listingBtns">
                <p>
                  {itm.p_type === "commercial"
                    ? `₹${priceconvert(itm.amount)}/- SQ. FT.`
                    : priceconvert(itm.amount) === 0
                    ? "Price On Request"
                    : `₹${priceconvert(itm.amount)}/-`}
                </p>
                <div className="controlList">
                  <Link to="tel:8178749372">Booking Now</Link>
                  <Link to={`../${itm.url}`}>View More</Link>
                </div>
              </div>
            </div>
          </div>
          
        ))
      )}
      </div>
    </>
  );
};

export default Right;
