import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import { useGetOthersQuery } from '../echron/clientapi'
import Loader from './Loader'
import "../assets/css/Footer.css";
import { NavLink } from "react-router-dom";
import Form_bottom from "./Form_bottom";

function Footer() {
  const locsRes = useGetOthersQuery({type: 'location', chain: '2', parent_id: 'null'});
	if(locsRes.isLoading) return <Loader mtop="100px" />
	if(locsRes.isError) return <h1>An Error occured {locsRes.error.error}</h1>
  if(locsRes.isSuccess) {
    return (
      <footer>
        <div className="main-footer">
          <Container>
            <Row>
              <Col lg="6">
                <div className="content pr">
                  <div className="text1">
                    <h2 className="h2">About Property4Sure</h2>
                    <p>
                    Property4Sure - A Unit of Deswal and Sons Consultants Pvt. Ltd. (Gurgaon) is a leading name in the real-estate business that offers Residential, Commercial, and Industrial Properties to the clients at unbelievable rates. Best-known for the quality of services understanding the rudiments of faith and transparency, Property4Sure believes in bringing the best to the clients. Covering whole of India, you can dins the best options of properties for Sale/Rent.
                    </p>
                  </div>
                  {/* <div className="text2">
                    <h2 className="h2">More from our Network</h2>
                    <div className="colm">
                      <div className="fifth">
                        <li>Times of India</li>
                        <li>MensXP</li>
                      </div>
                      <div className="fifth">
                        <li>Economic Times</li>
                        <li>iDiva </li>
                      </div>
                      <div className="fifth">
                        <li>Navbharat times</li>
                        <li>TimesJobs</li>
                      </div>
                      <div className="fifth">
                        <li>India Times</li>
                        <li>Gatgets Now </li>
                      </div>
                      <div className="fifth">
                        <li>Filmfare</li>
                      </div>
                    </div>
                  </div> */}
                  <div className="row mone">
                    {/* <Col lg="6" className="p-0">
                      <div className="app">
                        <a href="#">
                          <img src={playstore} />
                        </a>
                        <a href="#">
                          <img src={appstore} />
                        </a>
                      </div>
                    </Col> */}
                    <Col lg="6">
                      <div className="icon">
                        <ul>
                          <li className="fb">
                            <a href="https://www.facebook.com/property4sure.India">
                            <i className="fa-brands fa-facebook-f"></i>
                            </a>
                          </li>
                          <li className="tr">
                            <a href="https://twitter.com/Property4Sure">
                              <i className="fa-brands fa-twitter"></i>
                            </a>
                          </li>
                          <li className="in">
                            <a href="https://www.linkedin.com/company/property4sure/">
                              <i className="fa-brands fa-linkedin-in"></i>
                            </a>
                          </li>
                          <li className="ig">
                            <a href="https://www.instagram.com/property4sure/">
                              <i className="fa-brands fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="content pr">
                  <div className="text3">
                    <h2 className="h2">Property in India</h2>
                    <ul>
                      {
                        locsRes.data.location.map((post)=>
                          <li><NavLink key={post.id} to={post.url}>Property in {post.name}</NavLink></li>
                        )
                      }
                    </ul>
                    
                  </div>
                  <div className="text4">
                    <h2 className="h2">New Projects in India</h2>
                    <li>
                      New Projects in New Delhi | New Projects in Mumbai | New
                      Projects in Channai
                    </li>
                    <li>
                      New Projects in Pune | New Projects in Noida | New Projects
                      in Gurgaon
                    </li>
                    <li>
                      New Projects in Bangalore | New Projects in Ahmedabad
                    </li>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="footer">
            <ul>
              {/* <li>
                <a href="#">Sitemap</a>
              </li> */}
              <li>
                <a href="#">Terms & Conditions</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Blog</a>
              </li>
              {/* <li>
                <a href="#">Career</a>
              </li>
              <li>
                <a href="#">Testimonials</a>
              </li>
              <li>
                <a href="#">Feedback</a>
              </li>
              <li>
                <a href="#">Unsubcribe</a>
              </li>
              <li>
                <a href="#">help Center</a>
              </li> */}
              <li>
                <a href="#">Sales Enquiry</a>
              </li>
              <li>
                <a href="#">Our Service</a>
              </li>
            </ul>
          </div>
          <div className="foot">
            <Container>
              <p>
              Property4Sure is only an intermediary offering its platform to facilitate the transactions between Seller and Customer/Buyer/User and is not and cannot be a party to or control in any manner any transactions between the Seller and the Customer/Buyer/User. Property4Sure shall neither be responsible nor liable to mediate or resolve any disputes or disagreements between the Customer/Buyer/User and the Seller and both Seller and Customer/Buyer/User shall settle all such disputes without involving Property4Sure in any manner.
              </p>
            </Container>
          </div>
        </div>
        <Form_bottom />
        
      </footer>
    );
  }
}
export default Footer;
