import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetSearchSubLokQuery } from "../echron/clientapi";

const SubLocation = ({ loksid, subloksns, activeArray }) => {
  const navigate = useNavigate();
  const sublocation = useGetSearchSubLokQuery();
  const [sblok, setSblok] = useState([]); 

  const onloadLokfuns = (loksid, sublocation) => {
    if (sublocation.data) {
      const filteredSublocations = loksid.flatMap((i) =>
        sublocation.data.filter((sl) => sl.parent_id === i)
      );
      setSblok(filteredSublocations);
    }
  };

  useEffect(() => {
    onloadLokfuns(loksid, sublocation);
  }, [loksid, sublocation]);

  const handleSublok = (option) => {
    // Normalize the option if it's 'nh 8'
    const normalizedOption = option === 'nh 8' ? 'nh8' : option;
    subloksns(normalizedOption);
  };

  return (
    <div className="filter localities">
      <div className="filterhead">
        <h6>Localities</h6>
        <i className="fa-solid fa-angle-down"></i>
      </div>
      <div className="lokflilterbox">
        {sblok.map((itms, key) => (
          <div className="localityfilter" key={key}>
            <input
              type="radio"
              name="sbtn"
              onClick={() => handleSublok(itms.name.toLowerCase())}
              checked={activeArray.includes(itms.name.toLowerCase() === 'nh 8' ? 'nh8' : itms.name.toLowerCase())}
            />
            {itms.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubLocation;
