import { createSlice } from '@reduxjs/toolkit';

const scrollSlice = createSlice({
  name: 'scroll',
  initialState: {
    isScrolled: false,
  },
  reducers: {
    setScroll: (state, action) => {
      state.isScrolled = action.payload;
    },
  },
});

export const { setScroll } = scrollSlice.actions;
export const selectScroll = (state) => state.scroll.isScrolled;

export default scrollSlice.reducer;