import { createSlice } from '@reduxjs/toolkit';

const slickSlice = createSlice({
  name: 'slick',
  initialState: {
    m1: {
      infinite: true,
      speed: 400,
      slidesToShow: 2,
      autoplay: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows : false,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows : false,
            slidesToScroll: 1
          }
        }
      ]
    },


    m2: {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      autoplay: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows : false,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows : false,
            slidesToScroll: 1
          }
        }
      ]
    },


  },
  reducers: {
    
  },
});


export const selectSlick = (state) => state.slick;

export default slickSlice.reducer;