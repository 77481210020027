import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useGetDevsQuery } from '../echron/clientapi';

const DevloperFilter = ({devloprFilter, ActiveFilterData}) => {

const navigate = useNavigate();
const devsRes = useGetDevsQuery();
const [showAll, setShowAll] = useState(false);
const url = window.location.pathname;
const urlreplace = url.replace("/search/", '').replace(/\+/g, ' ');

const developers = devsRes?.data?.developer || [];
const filteredDevelopers = developers.filter((post) => post.name.length > 5);
const developersToShow = showAll
  ? filteredDevelopers
  : filteredDevelopers.slice(0, 5);


  const handleDelvoper = (option) => {
    devloprFilter(option);

  };



  return (
    <>
      <div className="filter">
            <div className="filterhead">
              <h6>Popular Developers</h6>
              <i class="fa-solid fa-angle-down"></i>
            </div>
            <div className="lokflilterbox">
              {developersToShow.length > 0 ? (
                developersToShow.map((itm) => (
                  <div className="localityfilter" key={itm.id}>
                    <input
                      type="radio"
                      onChange={() => {
                        handleDelvoper(itm.name.toLowerCase());
                      }}
                      id={`checkbox-${itm.id}`}
                      name="devloper"
                      checked={ActiveFilterData.devs.includes(itm.name.toLowerCase())}
                    />
                    <label htmlFor={`checkbox-${itm.id}`}>{itm.name}</label>
                  </div>
                ))
              ) : (
                <p>No developers found</p>
              )}

              <button onClick={() => setShowAll(!showAll)}>
                {showAll ? "Show Less" : "Show All"}
              </button>
            </div>
          </div>
    </>
  )
}

export default DevloperFilter
