import { Container, Row, Col} from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import z1 from '../assets/img/z1.jpeg'
import z4 from '../assets/img/z4.jpg'
import z3 from '../assets/img/z3.jpg'
import '../assets/css/Lease.css'
function Lease() {
  return (
       
            <section className='Echron-Commrs'>
                <Container>
                      <Row>
                        <div className='text'>
                            {/* <p>COMMERCIAL SPACE</p> */}
                            <h3>Choose from a wide variety of</h3>
                            <h3>Commercial properties</h3>
                        </div>
                        <Col lg={12}>
                          <div className='li'>
                            <figure>
                                <img src={z1} alt="" />
                            </figure>
                            <div className='Matter'>
                              <p>LEASE FOR COMMERCIAL USE</p>
                              <h2 className='h2'>Lease a Commercial<div className='proprty'>property</div></h2>
                              <div className='Contain'>Explore from office Spaces, Co-working spaces,</div>
                              <div className='Containt'>Retail Shops, Land Factories and more</div>
                              <button className="button"><a href="../commercial" >Explore Leasing Commercial</a></button>
                            </div>
                          </div>
                        </Col>
                        {/* <Col lg={4}>
                          <div className='li'>
                            <figure>
                                <img src={z4} alt="" />
                            </figure>
                            <div className='Matter'>
                              <p>LEASE FOR COMMERCIAL USE</p>
                              <h2 className='h2'>Lease a Commercial<div className='proprty'>property</div></h2>
                              <div className='Contain'>Explore from office Spaces, Co-working spaces,</div>
                              <div className='Containt'>Retail Shops, Land Factories and more</div>
                              <button className="button">Explore Leasing Commercial</button>
                            </div>
                            
                          </div>
                        </Col> */}
                        {/* <Col lg={4}>
                          <div className='li'>
                            <figure>
                                <img src={z3} alt="" />
                            </figure>
                            <div className='Matter'>
                              <p>LEASE FOR COMMERCIAL USE</p>
                              <h2 className='h2'>Lease a Commercial<div className='proprty'>property</div></h2>
                              <div className='Contain'>Explore from office Spaces, Co-working spaces,</div>
                              <div className='Containt'>Retail Shops, Land Factories and more</div>
                              <button className="button">Explore Leasing Commercial</button>
                            </div>
                            
                          </div>
                        </Col> */}
                    </Row>
                </Container>
            </section>
    )
  }
  export default Lease