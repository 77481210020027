import React, { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";

import Property from "./Property_slider";
import Carousel from "react-bootstrap/Carousel";
import Logo1 from "../assets/img/black-logo.png";
import Map from "../assets/img/map.jpg";
import Popup from "../component/Popup";
import Form_side from "../component/Form_side";
import FootLinks from './FootLinks'
import "../assets/css/Detail.css";
import { setPopup, selectPopup } from "../echron/popupSlice";
import { useDispatch, useSelector } from "react-redux";
function Detail(props) {

console.log("detail : ", props);

  const dispatch = useDispatch();
  const isPopActive = useSelector(selectPopup);

  const echronEvPop = () => {
    dispatch(setPopup(true));
  };
  function numDifferentiation(val) {
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    }
    else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' Lac';
    }
    /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
    return val;
  }

  

  let priceComer;
  if(props.p_type==='residential') {
    priceComer = ''
  }
  else {
    priceComer = '/Sq Ft'
  }




  //props.area_m
  const echronPrice = props.price? <><i className="fa fa-inr" aria-hidden="true"></i> <b>{numDifferentiation(props.price)}{priceComer}</b></> : '*Price on Request*'
  const echronPriceMax = props.price_m? <> - <b>{numDifferentiation(props.price_m)}</b></> : null
  const echronAreaMax = props.area_m? <> - {props.area_m}</> : null

  const [isActive, setIsActive] = useState("overview");

  const scrollRef1 = useRef(null);
  const scrollRef2 = useRef(null);
  const scrollRef3 = useRef(null);
  const scrollRef4 = useRef(null);
  const scrollRef5 = useRef(null);
  const scrollRef6 = useRef(null);

  const echronEvScroll = (tab, ref) => {
    setIsActive(tab);
    if (ref && ref.current) {
      const { offsetTop } = ref.current;
      const scrollTop = offsetTop - 100;

      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  if (props.amenity.length > 0) {
    var amenity = (
      <>
        <div ref={scrollRef3} className="EchronAmenities">
          <h3 className="echron-common-h1">
            Amenities
          </h3>
          <ul>
            {props.amenity.map((amenity, i) => {
              const icon = amenity.icon
                ? "fa fa-" + amenity.icon
                : "fa fa-building";
              return (
                <li key={i}>
                  <i className={icon}></i> {amenity.name}
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );

    var amenityTab = (
      <li
        className={isActive === "amenities" ? "active" : ""}
        onClick={echronEvScroll.bind(this, "amenities", scrollRef3)}
      >
        Amenities
      </li>
    );
  }
  if (props.specification_arr.length > 0) {
    var specification = (
      <>
        <div ref={scrollRef4} className="EchronSpecific">
          <h3 className="echron-common-h1">Specification</h3>
          <ul>
            {props.specification_arr.map((specification, i) => {
              return <li key={i}>{specification}</li>
            })}
          </ul>
        </div>
      </>
    );

    var specificationTab = (
      <li
        className={isActive === "specification" ? "active" : ""}
        onClick={echronEvScroll.bind(this, "specification", scrollRef4)}
      >
        Specification
      </li>
    );
  }

  if (props.payment_term.length > 0) {
    var payment = (
      <>
        <div ref={scrollRef5} className="EchronPayment">
          <h3 className="echron-common-h1">Payment Of {props.name}</h3>
          <div className="table-responsive">
            <table className="table">
              <tr>
                <th>Sl No.</th>
                <th>Installment Description</th>
                <th>% Due of Unit Price</th>
              </tr>
              {props.payment_term.map((payment, i) => {
                return  <tr key={i}>
                          <td>{payment.col1}</td>
                          <td>{payment.col2}</td>
                          <td>{payment.col3}</td>
                        </tr>
              })}
            </table>
          </div>
        </div>
      </>
    );

    var paymentTab = (
      <li
        className={isActive === "payment" ? "active" : ""}
        onClick={echronEvScroll.bind(this, "payment", scrollRef5)}
      >
        Payment
      </li>
    );
  }

  if (props.map) {
    var map = (
      <>
        <div ref={scrollRef6} className="EchronMap">
          <div className="map">
            <h3 className="echron-common-h1" >Property Map</h3>
            <iframe src={props.map} width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </>
    );

    var mapTab = (
      <li
        className={isActive === "map" ? "active" : ""}
        onClick={echronEvScroll.bind(this, "map", scrollRef6)}
      >
        Map
      </li>
    );
  }

 

  return (
    <section className="echron-detail">
      {props.banner === "NOT" ? (
        ""
      ) : (
        <div className="banner">
          <img src={props.banner} alt="" />
          {/* <h2 className="banner-text"> {props.n_name} </h2> */}
        </div>
      )} 
      {/* <div className="container-fluid">
        <ul className="breadcrumbs">
          <li>
            <NavLink to="/">Home</NavLink> &gt;
          </li>
          {props.location.slice(0).map((loc) => (
            <li>{loc} &gt;</li>
          ))}
          <li className="active">
            <b>{props.name}</b>
          </li>
        </ul>
      </div> */}
      <div className="body container-fluid">
        <Row>
          <Col lg="8" className="left-container">
            <div className="left">
              <div className="heading">
                <div className="heading-rera">
                  <h1>{props.name}</h1>
                  <div className="tag">
                      {" "}
                      Rera <i
                        className="fa fa-check"
                        aria-hidden="true"
                      ></i>{" "} <p>{props.rera? props.rera : ''}</p>
                    </div>
                  </div>
               
                
                
                <div className="d-flex">
                  <div className="left-side">
                    
                    <p>
                      <i className="fa-solid fa-location-dot"></i>{" "}{" "}
                      {props.address}
                      {/* {
                        props.location.slice(0).reverse().map((loc, index, array) => {
                          var commaaa = (index === array.length - 1)? '' : ', '
                          return (
                            <span key={index}>{loc}{commaaa}</span>
                          )
                        })
                      } */}

                    </p>
                   
                    <p>
                      <i className="fa fa-building" aria-hidden="true"></i>{" "}
                      {
                        props.categroy.slice(0).reverse().map((loc, index, array) => {
                          var commaaa = (index === array.length - 1)? '' : ', '
                          return (
                            <span key={index}>{loc}{commaaa}</span>
                          )
                        })
                      }
    

                </p>
                    {/* <div className="tag">
                      {" "}
                      Rera <i
                        className="fa fa-check"
                        aria-hidden="true"
                      ></i>{" "}
                    </div> */}
                    
                  </div>
                  <div className="right">
                  
                    <p className="blk">
                      {echronPrice} {echronPriceMax}
                    </p>

                     <p>
                    <i className="fa-solid fa-chart-area"></i> {props.area} {echronAreaMax} SQ. FT.
                    </p>
                    
                  </div>

                  

                </div>
              </div>
              <div className="EchronHomebanner">
                <Carousel>
                  {props.gallery.map((gal) => (
                    <Carousel.Item key={gal.id}>
                      <div className="banner-image">
                        <img src={gal.image} alt="" />
                        {/* <div className="icon1">
                          <i className="fa fa-heart"></i>
                        </div> */}
                        {/* <div className="icon2">
                          <i className="fa fa-share-alt" aria-hidden="true"></i>
                        </div> */}
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className="EchronTab">
                <ul className="overview-tab nav nav-tabs">
                  <li
                    className={isActive === "overview" ? "active" : ""}
                    onClick={echronEvScroll.bind(this, "overview", scrollRef1)}
                  >
                    Overview
                  </li>
                  <li
                    className={isActive === "floorplan" ? "active" : ""}
                    onClick={echronEvScroll.bind(this, "floorplan", scrollRef2)}
                  >
                    Floor Plan
                  </li>
                  {amenityTab}
                  {specificationTab}
                  {paymentTab}
                  {mapTab}
                </ul>
              </div>

              <div ref={scrollRef1} className="EchronDetail">
                <h3 className="echron-common-h1">{props.name}</h3>
                <p>{props.description}</p>
                <ul className="row g-2">
                  <li className="col-6 col-md-4">
                    <div className="EchronDetail_textoverview">
                      <p>Property Id: </p>
                      <b>PFS{props.id} </b>
                    </div>
                  </li>
                  <li className="col-6 col-md-4">
                    <div className="EchronDetail_textoverview">
                      <p>Price: </p>
                      <b>{echronPrice} {echronPriceMax}</b>
                    </div>
                  </li>

                  { (props.p_type==='residential') ?
                    (<li className="col-6 col-md-4">
                      <div className="EchronDetail_textoverview">
                        <p>BHK: </p>
                        <b>{props.bedroom} BHK</b>
                      </div>
                    </li>) : null
                  }

                  {/* <li className="col-6 col-md-4">
                    <div className="EchronDetail_textoverview">
                      <p>Bathroom: </p>
                      <b>{props.bathroom} </b>
                    </div>
                  </li> */}
                  {props.furnished !== null ? (
                  <li className="col-6 col-md-4">
                    <div className="EchronDetail_textoverview">
                      <p>Status: </p>
                      <b>{props.furnished} </b>
                    </div>
                  </li>
                  ) : null}
                  {props.total_floor !== 0 ? (
                    <li className="col-6 col-md-4">
                      <div className="EchronDetail_textoverview">
                        <p>Total Floor: </p>
                        <b>{props.total_floor}</b>
                      </div>
                    </li>
                  ) : null}
                  {props.area !== 0 ? (
                  <li className="col-6 col-md-4">
                    <div className="EchronDetail_textoverview">
                      <p>Area: </p>
                      <b>{props.area} {echronAreaMax} sqft</b>
                    </div>
                  </li>
                   ) : null}
                  {props.year_built !== 0 ? (
                    <li className="col-6 col-md-4">
                      <div className="EchronDetail_textoverview">
                        <p>Year Built: </p>
                        <b>{props.year_built}</b>
                      </div>
                    </li>
                  ) : null}

                  <li className="col-6 col-md-4">
                    <div className="EchronDetail_textoverview">
                      <p>For: </p>
                      <b>{props.p_for} </b>
                    </div>
                  </li>
                  <li className="col-6 col-md-4">
                    <div className="EchronDetail_textoverview">
                      <p>Property Type: </p>
                      <b>
                        {" "}
                        {props.categroy.map((cat, i) => {
                          const catname =
                            i !== props.categroy.length - 1 ? cat + ", " : cat;
                          return <span key={i}>{catname}  </span>;
                        })}{" "}
                      </b>
                    </div>
                  </li>
                  <li className="col-6 col-md-4">
                    <div className="EchronDetail_textoverview">
                      <p>Maintenance: </p>
                      <b>{props.maintance === 1 ? "Yes" : "No"}</b>
                    </div>
                  </li>
                  <li className="col-6 col-md-4">
                    <div className="EchronDetail_textoverview">
                      <p>Parking: </p>
                      <b>{props.parking}</b>
                    </div>
                  </li>
                </ul>

                <Row>
                  <Col lg="12">
                    <div className="rera">RERA ID</div>
                  </Col>
                  <Col lg="12">
                    <div className="num">
                      <span>RERA NO :</span> {props.rera? props.rera : 'COMING SOON'}
                    </div>
                    <button
                      className="echron-button"
                      onClick={echronEvPop.bind()}
                    >
                      Inquire Now !
                    </button>
                  </Col>
                </Row>
              </div>
              <div ref={scrollRef2} className="EchronPlan">
                <h3 className="echron-common-h1">Floor Plan</h3>
                <Row>
                  <Col lg="9">
                    <div className="top">
                      <div className="logo">
                        <p>&nbsp;</p>
                        <img src={Logo1} alt="" />
                      </div>
                      <div className="text">
                        <h3>{props.area} {echronAreaMax} SQ. FT.</h3>
                        <p>
                          {props.name}
                        </p>
                      </div>
                    </div>
                    <div className="bottom" onClick={echronEvPop.bind()}>
                      {/* <img src={Map} alt="" /> */}
                        {
                          props.thumb && props.thumb.length > 0 && (
                           <img src={props.thumb[1] ? props.thumb[1].thumbImage: Map  } alt="Image description" />
                       ) }
                      <p className="floorplan-text">FLOOR PLAN</p>
                    </div>
                  </Col>
                  <Col lg="3">

                    {/* { (props.p_type=='residential') ?
                      (<div className="cont">
                      <p>{props.bedroom} BHK</p>
                      <p>{props.address}</p>
                    </div>) : null
                    } */}

                    <div className="cont">
                      <p>Locality</p>
                      <p>{props.address}</p>
                    </div>

                    <div className="cont">
                      <p>Area</p>
                      <p>{props.area} {echronAreaMax} SQ.FT.</p>
                    </div>
                    <div className="cont">
                      <p>Starting Price</p>
                      <p>{echronPrice}</p>
                    </div>
                  </Col>
                </Row>
              </div>
              {amenity}
              {specification}
              {payment}
              {map}
            </div>
          </Col>
          <Col lg="4" className="right-container" >
            <div class="question">
              <h3>Got a Question?</h3>
              <p>Our Property Expert will be happy to help you resolve any queries you have about this property..</p>
              <a href="tel:8178749372" ><i class="fa-solid fa-phone fa-shake"> </i>  +91- 8178749372 , </a>
              <a href="tel:9910902063" > 9910902063  </a>
              <p>10:30 AM - 8:00 PM (Mon to Sat)</p>
            </div>
            <div className="EchronRight">
              <Form_side />
            </div>
           

          </Col>
        </Row>
      </div>
      <section className="echron-cards">
        <Property locationss={props.location} categoryss={props.categroy}/>
      </section>
      <FootLinks />
      
      {isPopActive && <Popup />}
    </section>
  );
}
export default Detail;
