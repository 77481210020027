//common index component
import Header from '../component/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../component/Footer'
function Echrontech() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}
export default Echrontech