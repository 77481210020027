import React, { useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap";
import "../assets/css/Footer.css";
import { setScroll, selectScroll } from "../echron/scrollSlice";
import { echronEvInput, useSubmitForm, selectFormResp } from "../echron/formSlice";
import { useDispatch, useSelector } from 'react-redux';
import phone from "../assets/img/footer/phone.png";
import user from "../assets/img/footer/user.png";
import Warning from "./Warning";
function Form_bottom() {
  
  /* setEchronSt(prevState => ({
    ...prevState, // Maintain other properties
    name: value, // Update name
  })); */

  /* const echronEvInput = (value, type) => {
    const formData = {};
    formData[type] = value;
    dispatch(setForm(formData));
  } */
  
  //scroll event
  const dispatch = useDispatch();
  const isScrolled = useSelector(selectScroll);

  useEffect(() => {
    const handleScroll = () => {
      dispatch(setScroll(window.scrollY > 200));
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dispatch]);

  

  //post api
  const submitForm = useSubmitForm();
  const formResps = useSelector(selectFormResp);
  const echronEvSubmit = () => {
    if(!formResps.load) {
      submitForm();
    }
  }


  //warning
  const errorBody =
      <div className="alert alert-danger"> 
        <ul>
          {
            formResps.errorList.map((list, i)=>
              {
                return <li key={i}>{list}</li>
              }
            )
          }
        </ul>
      </div>
  const errorHead = <h5 className="text-danger">Error Alert</h5>

  return (
    <div className={isScrolled ? "feet active" : "feet"} >
      <Container>
        <div className="form">
          <Row>
            {/* <Col lg="1" className="d-none d-sm-block">
              <figure>
                <img src={phone} />
              </figure>
            </Col> */}
            <Col lg="12">
              <form>
                <Row>
                  <Col xs="6" md="3">
                    <input
                      type="text"
                      onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'name'}))}}
                      placeholder="Enter Your Name*"
                      className="form-control"
                    />
                  </Col>
                  <Col xs="6" md="3">
                    <input
                      type="text"
                      onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'email'}))}}
                      placeholder="Enter Your Email*"
                      required=""
                      className="form-control"
                    />
                  </Col>
                  <Col xs="6" md="3">
                    <input
                      type="phone"
                      onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'phone'}))}}
                      placeholder="Enter Your Phone*"
                      className="form-control"
                    />
                    {/* <Row className="g-0">
                      <Col xs="5" md="4">
                        <select className="form-select">
                          <option selected>+91 India...</option>
                          <option>America</option>
                          <option>China</option>
                        </select>
                      </Col>
                      <Col xs="8" md="7">
                        <input
                          type="phone"
                          onChange={(e)=>echronEvInput(e.target.value, 'phone')}
                          placeholder="Enter Your Phone"
                          className="form-control"
                        />
                      </Col>
                    </Row> */}
                  </Col>
                  <Col xs="6" md="3">
                    <button type="button" onClick={()=>echronEvSubmit()} className="button">
                      {formResps.load ? 'Please Wait...' : 'Get a Call'}
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
            {/* <Col lg="1" className="d-none d-sm-block">
              <figure>
                <img src={user} />
              </figure>
            </Col> */}
          </Row>
        </div>
      </Container>
      {formResps.error && <Warning body={errorBody} head={errorHead} />}
    </div>
  );
}
export default Form_bottom;