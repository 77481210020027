import React, { useEffect } from "react"
import { Container, Row, Col } from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import Navi from '../component/Navi'
import { setNav, selectNav, setAnimNav, selectAnimNav } from "../echron/navSlice"
import '../assets/css/Header.css'
import Logo1 from '../assets/img/black-logo.png'
import Logo2 from '../assets/img/logo-white.png'

import { setScroll, selectScroll } from "../echron/scrollSlice"
import { useDispatch, useSelector } from 'react-redux'

function Header() {
  const dispatch = useDispatch();
  const isScrolled = useSelector(selectScroll);
  const isNav = useSelector(selectNav);
  const animNav = useSelector(selectAnimNav);

  //const animate = isNav? 'animate' : '';
  let animate = '';

  const echronEvNav = () => {
    dispatch(setNav(true));
    setTimeout(function() { //Start the timer
      dispatch(setAnimNav('animate')); //After 1 second, set render to true
    }.bind(this), 100)
    
  };
  
  useEffect(() => {
    const handleScroll = () => {
      dispatch(setScroll(window.scrollY > 200));
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dispatch]);
  return (
    <header className={isScrolled ? "echron-header fixed" : "echron-header"} >
      <Container>
        <Row>
          <Col lg="2" className='header'>
            <div className='logo'>
            <NavLink to="/"><img className='no-fixed'src={Logo1} alt="" /></NavLink>
            <NavLink to="/"><img className='yes-fixed'src={Logo2} /></NavLink>
            </div>
          </Col>
          <Col lg="10" className='mone'>
            <nav>
              <ul>
              <li className="number"><a href="tel:+918178749372"> <i className="fa fa-phone"></i> IN +91-817-874-9372</a></li>
                {/* <li className="active"><NavLink to="/"> <i className="fa fa-book"></i> Newz </NavLink></li>
                <li><NavLink to="/about"><i className="fa fa-heart"></i> favorites</NavLink></li>
                <li><NavLink to="/"><i className="fa fa-sign-in"></i> Login</NavLink></li> */}
                <li className="icon header-button" onClick={echronEvNav.bind()}><i className="fa fa-bars"></i></li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>

			{isNav && <Navi animate={animNav} />}
     
    </header>
    
  );
}
export default Header;
