import { useEffect, useState } from 'react';
import { Banner1 } from "./Image";
import "../assets/css/Banner.css";
import { useGetOthersQuery, useGetSearchPostQuery } from "../echron/clientapi";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from './Loader';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function Banner(props) {
  const [apartmnt, setApartmnt] = useState([]);
  const { data, isError, isLoading } = useGetSearchPostQuery();
  const [result, setResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');
  const [activeIndex, setActiveIndex] = useState(-1);
  const [show, setShow] = useState(false);
  const [echronSt, setEchronSt] = useState({ cityId: 0, cityUrl: '', localityId: 0, localityUrl: '' });
  const [headings, setHeadings] = useState(null);
  const navigate = useNavigate();
  
  const location = useLocation();
  const path = location.pathname;
  const searchPath = path.replace("/search/", "");
  let heading = searchPath.replace(/[+/]/g, " ");
 
  const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);

  const startListening = () => {
    SpeechRecognition.startListening({ language: 'en-IN' });
    setIsListening(true);
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);
  };
  

  useEffect(() => {
    if (data) {
      setApartmnt(data.post);
    }
   
    setHeadings(heading.trim().replace("voice&", "").replace("tagline=", ""));
    const voicetxt = transcript.replace(/[.,]/g, "").toLowerCase();
    setSearchTerm(voicetxt);
    
  }, [data, heading, transcript]);
 

 

  const [lidata, setLiData] = useState('All');

  useEffect(() => {
    const storedValue = localStorage.getItem('ctr_key');
    if (storedValue) {
      setLiData(storedValue);
    }
  }, []);

  const lifunction = (event) => {
    const newValue = event.currentTarget.textContent.trim();
    localStorage.setItem("ctr_key", newValue);
    
    setLiData(newValue);
  };

  useEffect(() => {
   
    const matchingTaglines = new Set();
    const cleanedSearch = searchTerm.replace(/\s+/g, '').toLowerCase();
    apartmnt.forEach((itm) => {
      if(itm.p_type.toLowerCase() === lidata.toLowerCase()){
      
      if (itm.taglines != null) {
        const taglinesArray = itm.taglines.split(',');
        taglinesArray.forEach((i) => {
          const cleanedTagline = i.trim().replace(/\s+/g, '').toLowerCase();
          if (cleanedTagline.includes(cleanedSearch)) {
            matchingTaglines.add(i.trim());
          }
        });
      }
    }else if(lidata == 'All'){
      if (itm.taglines != null) {
        const taglinesArray = itm.taglines.split(',');
        taglinesArray.forEach((i) => {
          const cleanedTagline = i.trim().replace(/\s+/g, '').toLowerCase();
          if (cleanedTagline.includes(cleanedSearch)) {
            matchingTaglines.add(i.trim());
          }
        });
      }
    }
    });
    setResult(Array.from(matchingTaglines));
  }, [searchTerm, apartmnt, lidata]);


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setHeadings(null);
    setActiveIndex(-1);
    localStorage.setItem('searchTerm', e.target.value);
  };

  const handleTagline = (x) => {
    let urlModify;
       const oturl = x
        .replace(/\b(2|3|4|5|6|7|8|9|10)\s+bhk\b/g, '$1bhk')
        .replace(/(\d+)\s+lakh/g, '$1lakh')
        .replace(/(\d+)\s+cr/g, '$1cr')
        .replace(/\bgurugram\b/g, 'gurgaon')
        .replace(/(\d+)\s*(sqft|sq ft|sq feet|square feet)/gi, '$1$2')
        .replace(/\s+/g, ' ')
        .replace('nh 8', 'nh8')
        .trim();
        if(x.includes("tagline=")){
          urlModify = oturl.replace(/ /g, "+").toLowerCase();
          let urlmdfy = "/search/"+urlModify;
          navigate(urlmdfy);
        }
        
  };
  

  const handleKeyPress = (e) => {
    if (e.key === 'ArrowDown') {
      setActiveIndex(prevIndex => Math.min(prevIndex + 1, result.length - 1));
      e.preventDefault();
  } else if (e.key === 'ArrowUp') {
    setActiveIndex(prevIndex => Math.max(prevIndex - 1, -1));
      e.preventDefault();
  } else if (e.key === 'Enter') {
      if (activeIndex >= 0 && activeIndex < result.length) {
        const selectedItem = result[activeIndex];
        setSearchTerm(selectedItem);
        handleTagline("tagline="+selectedItem);
        localStorage.setItem('searchTerm', selectedItem);
        setShow(false);
      }else{
        
        setSearchTerm(localStorage.getItem('searchTerm'));
        const wordsToRemove = /\b(is|am|are|was|which|where|under|above|space|the|for|and|&|on|property|properties|to|in)\b/g;
        let urlModify;
       const oturl = localStorage.getItem('searchTerm')
        .replace(wordsToRemove, '')
        .replace(/\b(2|3|4|5|6|7|8|9|10)\s+bhk\b/g, '$1bhk')
        .replace(/(\d+)\s+lakh/g, '$1lakh')
        .replace(/(\d+)\s+cr/g, '$1cr')
        .replace(/\bgurugram\b/g, 'gurgaon')
        .replace(/(\d+)\s*(sqft|sq ft|sq feet|square feet)/gi, '$1$2')
        .replace(/\s+/g, ' ')
        .replace('nh 8', 'nh8')
        .trim();
       
        urlModify = "search="+oturl.replace(/ /g, "+").toLowerCase();
        let urlmdfy = "/search/"+urlModify;
        navigate(urlmdfy);

      }
    }
  };

  const searchboardfun = () => {
    if (searchTerm.length > 1) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

 
  const sendSearchDataHandle = () => {
    let urlModify;
    const srchtrm = searchTerm === '' ? localStorage.getItem('searchTerm') : searchTerm;
  if(searchTerm.includes("tagline=")){

    const modifiedSearchTerm = searchTerm
    .replace(/\b(2|3|4|5|6|7|8|9|10)\s+bhk\b/g, '$1bhk')
    .replace(/(\d+)\s+lakh/g, '$1lakh')
    .replace(/(\d+)\s+cr/g, '$1cr')
    .replace(/\bgurugram\b/g, 'gurgaon')
    .replace(/(\d+)\s*(sqft|sq ft|sq feet|square feet)/gi, '$1$2')
    .replace('sq ft', 'sqft')
    .replace('sq feet', 'sqft')
    .replace('square feet', 'sqft')
    .replace(/\s+/g, ' ')
    .replace('nh 8', 'nh8')
    .trim(); 
   
    if(modifiedSearchTerm.replace(/ /g, "+").toLowerCase().includes('tagline=')){
        urlModify = modifiedSearchTerm.replace(/ /g, "+").toLowerCase();
    }
  }else{ 
  
    const modifiedSearchTerm = srchtrm
      .replace(/\b(2|3|4|5|6|7|8|9|10)\s+bhk\b/g, '$1bhk')
      .replace(/(\d+)\s+lakh/g, '$1lakh')
      .replace(/(\d+)\s+cr/g, '$1cr')
      .replace(/\bgurugram\b/g, 'gurgaon')
      .replace(/(\d+)\s*(sqft|sq ft|sq feet|square feet)/gi, '$1$2')
      .replace('sq ft', 'sqft')
      .replace('sq feet', 'sqft')
      .replace('square feet', 'sqft')
      .replace(/\s+/g, ' ')
      .replace('nh 8', 'nh8')
      .trim(); 
      if(window.location.pathname.includes('tagline=')){
          urlModify = "tagline="+modifiedSearchTerm.replace(/ /g, "+").toLowerCase();
      }else{
        urlModify = "search="+modifiedSearchTerm.replace(/ /g, "+").toLowerCase();
      }
  }
    const baseUrl = "/search";
    const localityPath = echronSt.localityId ? echronSt.localityUrl : echronSt.cityUrl;

    const searchPath = localityPath 
    ? `${baseUrl}${localityPath}/${urlModify}` 
    : `${baseUrl}/${urlModify}`;
    navigate(searchPath);
    
  };

  useEffect(() => {
    if (!listening && transcript !== '') {

      localStorage.setItem('searchTerm', searchTerm);
      let modifyVoiceUrl;
      const wordsToRemoves = /\b(is|am|are|was|which|where|under|above|space|the|for|and|&|on|property|properties|to|in)\b/g;
      const modifiedVoiceTerm = searchTerm
      .replace(wordsToRemoves, '')
      .replace(/\b(2|3|4|5|6|7|8|9|10)\s+bhk\b/g, '$1bhk')
      .replace(/(\d+)\s+lakh/g, '$1lakh')
      .replace(/(\d+)\s+cr/g, '$1cr')
      .replace(/\bgurugram\b/g, 'gurgaon')
      .replace(/(\d+)\s*(sqft|sq ft|sq feet|square feet)/gi, '$1$2')
      .replace('sq ft', 'sqft')
      .replace('sq feet', 'sqft')
      .replace('square feet', 'sqft')
      .replace(/\s+/g, ' ')
      .replace('nh 8', 'nh8') 
      .trim(); 
    modifyVoiceUrl = modifiedVoiceTerm.replace(/ /g, "+").toLowerCase();
    
    const searchPath2 = `/search${echronSt.localityId ? echronSt.localityUrl : echronSt.cityUrl}/voice=${modifyVoiceUrl}`;
    window.location.href = searchPath2;
    }
  }, [listening, transcript]);
 


  // if (isLoading) return <Loader />;
  if (isError) return <h1>An Error occurred: {isError.message}</h1>;


// Speach Loader start
let delay = 200;
let dots = document.querySelectorAll('.loader .dot');
dots.forEach((dot, index) => {
  dot.style.setProperty('--delay', `${delay * index}ms`);
});
// Speach Loader End



  if (!browserSupportsSpeechRecognition) {
    return null;
  }

  if(show){
    document.addEventListener('click', ()=>{
      setShow(false)
    })
  }


  return (
    <div className="EchronHomebanner">
    <div className="echron-main">
      <div className="banner-image">
        <img src={props.img2 || Banner1} alt="Banner" />
      </div>
      <div className="echron-content">
        <div className="heading">
          <h1 className="h1">Find Your Property</h1>
          <h2 className="h2">Discover the best real-estate properties in India</h2>
        </div>

        <div className="custom-select">
          <div className='filterTopbar'>
            <ul>
              <li onClick={lifunction} className={`searchCtr ${lidata === 'All' ? 'active' : ''}`}>All</li>
              <li onClick={lifunction} className={`searchCtr ${lidata === 'Residential' ? 'active' : ''}`}>Residential</li>
              <li onClick={lifunction} className={`searchCtr ${lidata === 'Commercial' ? 'active' : ''}`}>Commercial</li>
            </ul>
          </div>

          <div className='SearchFilterBox'>
           <i className="fa-solid fa-magnifying-glass search-front"></i>
            <input
              type='text'
              name="search"
              style={{ color: '#000' }}
              value={localStorage.getItem('searchTerm')}
              onChange={handleSearch}
              onKeyDown={handleKeyPress}
              onInput={searchboardfun}
              placeholder={"Search "+lidata}
              className='SearchPlaceholder'
              autocomplete = "off"         
            />
           
            
            <i className={`fa-solid fa-microphone microphone ${isListening == false ? 'active' : ''}`} onClick={startListening}></i>
            
            <button
            
              onClick={sendSearchDataHandle}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
          
          {
            
            listening ?
          <div className={`speachContaner ${isListening == true ? 'active' : ''}`}>
            <h3>Listing...</h3>
            <i class="fa-solid fa-xmark speachStop" onClick={stopListening}></i>
            {listening ? (
              <div className="loader js-loader" data-delay="200">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            ) : (
              <>
                <h4 style={{color:"#000"}}>Didn’t catch that.
            Try speaking again.</h4>
              <i
                className={`fa-solid fa-microphone microphone1 ${isListening === false ? 'active' : ''}`}
                onClick={startListening}
              ></i>
              </>
            )}
          </div>
          : ''
        }

        {
          result.length > 0 ?
        
          <div className={`searchboardBox ${show ? 'active' : 'dactive'}`}>
            {result.map((i, index) => (
              <div
                key={index}
                className={`ullistboard ${index === activeIndex ? 'active' : ''}`}
                onClick={() => {
                  localStorage.setItem('searchTerm', i);
                  setSearchTerm("tagline="+i);
                  setActiveIndex(index);
                  setShow(false);
                }}
                
              >
                <i className="fa-solid fa-magnifying-glass"></i>
                <p>{i}</p>
              </div>
            ))}
          </div>
           :null
          }
          
        </div>
       
    

        {/* <div className="key">
          <a href="../../commercial-properties">Discover Commercial Projects</a>
        </div> */}
        <div className="list">
          <ul>
            <li>
              <div className="span">5892</div>
              <div className="span">Projects</div>
            </li>
            <li>
              <div className="span">42</div>
              <div className="span">Cities</div>
            </li>
            <li>
              <div className="span">680</div>
              <div className="span">Developers</div>
            </li>
            <li>
              <div className="span">56798</div>
              <div className="span">Customers</div>
            </li>
            {/* <li>
              <div className="span">30500Cr</div>
              <div className="span">Worth Property Sold</div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Banner;
