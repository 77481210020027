import '../assets/css/Loader_route.css'
function Loader_route() {
    return (
      <div className="echron-loader" >
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    )
}
export default Loader_route