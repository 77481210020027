import React from 'react'
import { useNavigate } from "react-router-dom";

const BhkFilter = ({bhkfiltr, activeArray}) => {

const addbhk =(c)=>{
  bhkfiltr(c)    
}

let bhkurl = window.location.pathname;
const bhkurlreplace = bhkurl.replace("/search/", '').replace(/\+/g, ' ');


return (
    <>
       <div className="filter">
            <div className="filterhead">
              <h6>No. of Bedrooms</h6>
              <i class="fa-solid fa-angle-down"></i>
            </div>
            

            <ul>
              {[1, 2, 3, 4, 5, 6].map((count) => (
                <li
                  key={count}
                  className={activeArray.includes(count+"bhk") ? 'activeFilter' : ''}
                  onClick={()=>{
                    addbhk(count);
                  }}
                >
                  <i className="fa-solid fa-plus"></i>
                  {count === 6 ? "5 More" : `${count} BHK`}
                </li>
              ))}
            </ul>

          </div>
    </>
  )
}

export default BhkFilter
